var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dapp-logged-in-wrapper"},[(_vm.isUserLoggedIn)?_c('div',{staticClass:"dapp-login__card text-center p-8 shadow-lg rounded-lg"},[_c('h2',{staticClass:"mb-6 text-center text-2xl font-bold text-gray-900 pb-4 border-gray-100"},[_vm._v("Account details")]),_c('div',{staticClass:"flex flex-row flex-wrap content-center justify-center"},[_c('div',{staticClass:"dapp-login__card-address"},[_vm._v("Wallet address: ")]),_c('strong',{staticClass:"mb-6 block"},[_vm._v(_vm._s(_vm.$erd.obfuscatedWalletAddress))])]),_c('div',{staticClass:"flex flex-row justify-center content-center space-x-3"},[_c('a',{ref:"noreferrer",class:[
                    'dapp-login__card-logout',
                    'relative flex justify-center py-2 px-4 text-sm font-medium',
                    'rounded-md border-color-4 border color-4'
                ],attrs:{"href":_vm.getExplorerAddress,"target":"_blank"}},[_vm._v("View on Explorer")]),_c('button',{class:[
                    'dapp-login__card-logout',
                    'relative flex justify-center py-2 px-4 text-sm font-medium',
                    'rounded-md border-color-4 border color-4'
                ],on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_vm._v("Disconnect")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }