import gql from 'graphql-tag';

export const MutationLoginUser = gql`mutation loginUser($input: LoginInput!) {
  login(input: $input) {
    authToken
    refreshToken
    user {
      id
      name
    }
  }
}`;

export const MutationRegisterUser = gql`mutation RegisterUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    user {
      jwtAuthToken
      jwtRefreshToken
    }
  }
}`;

export const MutationRefreshAuthToken = gql`mutation RefreshAuthToken($input: RefreshJwtAuthTokenInput!) {
  refreshJwtAuthToken(input: $input) {
    authToken
  }
}`;