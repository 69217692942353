<template>
    <div class="navigation-wrap">
        <template v-if="getPlatform === 'ios'">
            <div class="navigation">
                <div class="mobile-nav md:hidden">
                    <ul class="grid grid-cols-11 gap-4">
                        <li></li>
                        <li class="nav-item col-span-2">
                            <router-link :to="{ name: 'map' }"
                                         class="nav-item__link">
                                <Icon name="location" class-name="nav-icon"/>
                                <span>{{ $t('navigation.map', 'MAP') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item col-span-5">
                            <router-link :to="{ name: 'scan' }"
                                         class="nav-item__link nav-item__scan">
                                <Icon name="qr" class-name="nav-icon"/>
                                <span>{{ $t('navigation.scan', 'SCAN QR') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item col-span-2">
                            <router-link :to="{ name: 'dashboard' }"
                                         class="nav-item__link">
                                <Icon name="profile" class-name="nav-icon"/>
                                <span>{{ $t('navigation.profile', 'PROFILE') }}</span>
                            </router-link>
                        </li>
                        <li></li>
                    </ul>
                </div>
                <template v-if="isUserLoggedIn">
                    <nav class="flex items-center justify-between flex-wrap py-6 hidden">

                        <div class="w-full block flex-grow md:flex md:items-center md:w-auto">
                            <div class="text-sm lg:flex-grow">
                                <router-link :to="{ name: 'homepage' }"
                                   class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Home
                                </router-link>
                                <router-link :to="{ name: 'scan' }"
                                   class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Scan
                                </router-link>
                                <router-link :to="{ name: 'cabinets' }"
                                   class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Cabinets
                                </router-link>

                            </div>
                            <div v-if="isAdmin" class="text-sm lg:flex-grow">
                                <router-link :to="{ name: 'admin' }" class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Admin
                                </router-link>
                                <router-link :to="{ name: 'admin-cabinets' }"
                                             class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Cabinets
                                </router-link>
                            </div>
                            <div>
                                <a href="#" @click.prevent="logout"
                                   class="inline-block text-sm px-4 py-2 leading-none border rounded mt-4 lg:mt-0">Logout</a>
                            </div>
                        </div>
                    </nav>
                </template>
                <template v-else>
                    <nav class="flex items-center justify-between flex-wrap p-6">
                        <div class="flex items-center flex-shrink-0 mr-6">
                            <span class="font-semibold text-xl tracking-tight">{{ app.NAME }}</span>
                        </div>
                        <div class="block lg:hidden">
                            <button class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                                <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                            </button>
                        </div>
                        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                            <div class="text-sm lg:flex-grow">
                                <router-link :to="{ name: 'homepage' }"
                                             class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Home
                                </router-link>
                            </div>
                            <div>
                                <router-link :to="{ name: 'login' }"
                                             class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 mr-4">
                                    Login
                                </router-link>
                            </div>
                        </div>
                    </nav>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="navigation-web">
                <button class="navigation-web__toggle" @click.prevent="toggleWebMenu()">
                    <Icon name="menu" />
                    <span>{{ $t('menu', 'Menu') }}</span>
                </button>

                <div class="navigation-web__modal" v-if="webMenuIsVisible">
                    <button class="navigation-web__modal-close" @click.prevent="toggleWebMenu()">
                        <span>{{ $t('close', 'Close') }}</span>
                        <Icon name="close" />
                    </button>
                    <ul class="nav-web-items">
                        <li class="nav-web-item">
                            <router-link :to="{ name: 'homepage' }"
                                         @click.native="toggleWebMenu"
                                         class="nav-item__link">
                                <span>{{ $t('navigation.home', 'Homepage') }}</span>
                                <Icon name="home" class-name="nav-web-icon"/>
                            </router-link>
                        </li>
                        <li class="nav-web-item">
                            <router-link :to="{ name: 'map' }"
                                         @click.native="toggleWebMenu"
                                         class="nav-item__link">
                                <span>{{ $t('navigation.find_closest', 'Find closest station') }}</span>
                                <Icon name="route" class-name="nav-web-icon"/>
                            </router-link>
                        </li>
                        <li class="nav-web-item">
                            <router-link :to="{ name: 'scan' }"
                                         @click.native="toggleWebMenu"
                                         class="nav-item__link nav-item__scan">
                                <span>{{ $t('navigation.enter', 'Rent a power bank') }}</span>
                                <Icon name="battery-charging" class-name="nav-web-icon"/>
                            </router-link>
                        </li>
                        <li class="nav-web-item" v-if="!isUserLoggedIn && !isUserLoggedInWithElrond">
                            <router-link :to="{ name: 'login' }"
                                         @click.native="toggleWebMenu"
                                         class="nav-item__link">
                                <span>{{ $t('navigation.profile', 'Login') }}</span>
                                <Icon name="profile2" class-name="nav-web-icon"/>
                            </router-link>
                        </li>
                        <template v-if="isUserLoggedIn || isUserLoggedInWithElrond">
                            <li class="nav-web-item">
                                <router-link :to="{ name: 'dashboard' }"
                                             @click.native="toggleWebMenu"
                                             class="nav-item__link">
                                    <span>{{ $t('navigation.profile', 'Profile page') }}</span>
                                    <Icon name="profile2" class-name="nav-web-icon"/>
                                </router-link>
                            </li>
                            <li class="nav-web-item">
                                <a href="#" @click.prevent="logout"
                                   class="nav-item__link">
                                    <span>{{ $t('navigation.logout', 'Logout') }}</span>
                                    <Icon name="profile-logout" class-name="nav-web-icon"/>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { APP } from "@/utils/constants";
import {
    USER_LOGOUT,
    USER_GET_LOGGED_IN,
    USER_SET_PROFILE_DATA
} from "@/store/modules/user";
import { QueryMyProfileData } from "@/graphql/profile";
import { USER_GET_PROFILE_DATA } from "../../store/modules/user";
import Icon from "../common/icon";
import {Capacitor} from "@capacitor/core";

export default {
    name: 'Navigation',
    components: {Icon},
    data () {
        return {
            app: '',
            webMenuIsVisible: false
        }
    },
    mounted() {
        this.app = APP;
        this.$root.$on('logout', this.logout);
    },
    methods: {
        logout() {
            this.$store.dispatch( 'user/' + USER_LOGOUT );
            this.$erd.logout();
            if( this.$router.currentRoute.name !== 'login' ) {
                this.$router.push({name: 'login'});
            }
            this.webMenuIsVisible = false;
        },
        toggleWebMenu() {
            this.webMenuIsVisible = !this.webMenuIsVisible;
        }
    },
    computed: {
        isUserLoggedIn() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        isUserLoggedInWithElrond() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
        userProfileData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        isAdmin() {
            return this.userProfileData.isAdmin;
        },
        getPlatform() {
            return Capacitor ? Capacitor.getPlatform() : 'web';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/navigation.scss';
</style>