<template>
    <div class="admin">
        <router-view></router-view>
    </div>
</template>

<script>
import Navigation from "../navigation/navigation";

export default {
    name: 'AdminShell',
    components: { Navigation }
};
</script>
