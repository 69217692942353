import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { vueErdJsStore, VueErdjsConnect } from 'vue-erdjs'

// Views
import Homepage from '@/components/homepage/homepage'
import NotFound from '@/components/not-found/not-found'
import Login from '@/components/login/login'
// import Register from '@/components/login/register'
import Dashboard from '@/components/dashboard/dashboard.vue'
import Scan from '@/components/cabinets/scan.vue'
import Map from '@/components/map/map.vue'
import Cabinets from '@/components/cabinets/cabinets.vue'
import Cabinet from '@/components/cabinets/cabinet.vue'
import Rent from '@/components/order/rent.vue'
import AdminShell from '@/components/admin/admin.vue'
import Admin from '@/components/admin/admin/admin.vue'
import AdminCabinets from '@/components/admin/cabinets/cabinets.vue'
import { USER_GET_LOGGED_IN } from "../store/modules/user";

import ErdjsRent from '@/dapp/rent/erdjs-rent.vue'

Vue.use( Router );
const config = {
    mode: 'history',
    linkExactActiveClass: 'is-active',
    routes: [
        {
            path: '/',
            name: 'homepage',
            component: Homepage,
            meta: {
                guest: true,
                title: 'Home'
            }
        },
        {
            path: '/404',
            name: 'not-found',
            component: NotFound,
            meta: {
                guest: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                guest: true,
                title: 'Login'
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: {
                label: 'Dashboard',
                title: 'Dashboard'
            },
            component: Dashboard
        },
        {
            path: '/scan',
            name: 'scan',
            component: Scan,
            meta: {
                label: 'Scan',
                title: 'Scan',
                guest: true,
            }
        },
        {
            path: '/map',
            name: 'map',
            component: Map,
            meta: {
                label: 'Map',
                title: 'Map',
                guest: true
            }
        },
        {
            path: '/cabinets',
            name: 'cabinets',
            component: Cabinets,
            meta: {
                label: 'Cabinets',
                title: 'Cabinets'
            }
        },
        {
            path: '/cabinet/:id?',
            name: 'cabinet',
            component: Cabinet,
            meta: {
                label: 'Cabinet',
                title: 'Cabinet',
                guest: true
            }
        },
        {
            path: '/rent/:id',
            name: 'rent',
            component: Rent,
            meta: {
                label: 'Rent powerbank',
                title: 'Rent powerbank'
            }
        },
        {
            path: '/rent-dapp/:id',
            name: 'rent-dapp',
            component: ErdjsRent,
            meta: {
                label: 'Rent powerbank',
                title: 'Rent powerbank'
            }
        },
        {
            path: '/admin',
            component: AdminShell,
            children: [
                {
                    path: '/',
                    name: 'admin',
                    component: Admin,
                    meta: {
                        label: 'Admin',
                        title: 'Admin'
                    },
                },
                {
                    path: 'cabinets',
                    name: 'admin-cabinets',
                    component: AdminCabinets,
                    meta: {
                        label: 'Cabinets',
                        title: 'Cabinets'
                    },
                },
            ]
        }
    ]
}

const router = new Router( config );

router.beforeEach( ( to, from, next ) => {
    try {
        isRouteAccessible(to);
        next();
    } catch (redirectedRoute) {
        next(redirectedRoute);
    }
} );

const isUserLoggedIn = () => {
    let loggedIn = store.getters[ 'user/' + USER_GET_LOGGED_IN ];

    if( !loggedIn ) {
        return vueErdJsStore.logged && vueErdJsStore.walletAddress && !vueErdJsStore.walletAddress.isEmpty();
    }

    return loggedIn;
}

const isRouteAccessible = function ( routeObject ) {
    if( routeObject.matched.length === 0 ) {
        throw {
            name: 'not-found'
        };
    }

    let userIsLoggedIn = isUserLoggedIn();
    let guestOnlyPage = routeObject.matched.some( record => record.meta.guest );

    if (!guestOnlyPage && !userIsLoggedIn) {
        throw {
            name: 'login',
            params: {
                nextUrl: routeObject.fullPath,
                routeObject: routeObject,
            }
        };
    }
};

export default router;