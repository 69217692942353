import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error'
import promiseToObservable from "./promiseToObservable";
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import {
    USER_GET_AUTH_TOKEN, USER_GET_REFRESH_TOKEN,
    USER_LOGOUT,
    USER_REFRESH_AUTH_TOKEN
} from "../store/modules/user";


Vue.use( VueApollo );

const getUri = () => {
    return process.env.VUE_APP_GRAPHQL_URL || 'http://localhost:8081/graphql'
}

// Setup apollo graphQl client for English endpoint.
const httpLink = new createHttpLink( {
    uri: getUri(),
    credentials: 'same-origin',
    // fetchOptions: {
    //     mode: 'no-cors',
    // },
} );

const getNewJwtToken = async () => {
    return await window.powerbank.$store.dispatch('user/' + USER_REFRESH_AUTH_TOKEN );
}

const errorLink = onError( ({ graphQLErrors, networkError, operation, forward }) => {
    let tryToRefreshToken = false;
    console.log(graphQLErrors)
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            // If error includes the message below, logout user.
            if ( err.message.includes('You must login to access this data')
                || err.message.includes('invalid-jwt')
                || err.message.includes('The provided refresh token is invalid')
                || ( err.debugMessage && err.debugMessage.includes('invalid-jwt') )
            ) {
                tryToRefreshToken = true;
            }
        }
    }

    // User is not authenticated or expired jwt auth token. Try to retrieve new token.
    if (tryToRefreshToken || networkError && networkError.statusCode && parseInt(networkError.statusCode) === 403 ) {
        // onError does not like async functions.
        // promiseToObservable is a hack stolen from https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
        return promiseToObservable(getNewJwtToken()).flatMap((value) => {
            if( value.refreshJwtAuthToken && value.refreshJwtAuthToken.authToken ) {
                // Modify the operation context with a new token
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                    headers: {
                        ...oldHeaders,
                        authorization: `Bearer ${value.refreshJwtAuthToken.authToken}`,
                    },
                });

                operation.setContext({
                    'fromError': true
                });

                return forward(operation);
            }
        });
    }
});

const authLink = setContext(async (operation, { headers, fromError }) => {
    // on login page, we don't need to send bearer.
    if ( window.powerbank.$route.name === 'login'
        || operation.operationName === 'RefreshAuthToken'
        || operation.operationName === 'loginUser'
        || operation.operationName === 'logoutUser'
        || operation.operationName === 'RequestAuthRefreshToken'
    ) {
        return headers;
    }

    // check if is set from our error handling function;
    // if yes, return headers.
    if ( fromError ) {
        return headers;
    }

    let token = window.powerbank.$store.getters[ 'user/' + USER_GET_AUTH_TOKEN ];
    let refreshToken = window.powerbank.$store.getters[ 'user/' + USER_GET_REFRESH_TOKEN ];

    if( !token && refreshToken ) {
        await window.powerbank.$store.dispatch( 'user/' + USER_REFRESH_AUTH_TOKEN );
        token = window.powerbank.$store.getters[ 'user/' + USER_GET_AUTH_TOKEN ];
    }

    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

export const apolloClient = new ApolloClient( {
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    connectToDevTools: true,
} );

export const apolloProvider = new VueApollo( {
    defaultClient: apolloClient,
    defaultOptions: {
        $loadingKey: 'loading'
    },
} );
