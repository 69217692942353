<template>
    <div class="rent-wrap max-w-md mx-auto">
        <div class="rent-form-wrap">
            <div class="flex flex-col">
                <div v-show="!clientSecret">
                    <loading-snippet></loading-snippet>
                </div>
                <div v-show="clientSecret && !releaseStatus && !releaseInProgress">
                    <div class="h-100">
                        <div class="flex flex-col">
                            <h2 class="payment-title">{{ $t('rent.payment_details', 'Payment details') }}</h2>
                            <div class="payment-description">
                                We'll hold a deposit form your card of €10. Once you'll return the power bank, the deposit will be returned to your bank account.
                            </div>
                            <div class="payment-description">
                                <strong>IMPORTANT: this is test payment form. Use the following card details:</strong>
                                <br/>
                                <p>Card number: <code>4242 4242 4242 4242</code></p>
                                <p>Expiration date: <code>12/24</code></p>
                                <p>CCV: <code>222</code></p>
                            </div>
                            <form id="payment-form">
                                <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                                <button :disabled="paymentInProgress" class="stripe-submit" id="submit">
                                    <div class="spinner hidden" id="spinner"></div>
                                    <span id="button-text">{{ $t('rent.pay', 'Pay') }}</span>
                                </button>
                                <p id="card-error" role="alert"></p>
                                <p class="result-message hidden">
                                    Payment succeeded, see the result in your
                                    <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
                                </p>
                            </form>
                            <router-link :to="{ name: 'cabinet', params: { id: $route.params.id } }" class="mb-7 cancel-rent">{{ $t('back', 'Back')
                                }}</router-link>
                        </div>
                    </div>
                </div>
                <div v-if="releaseInProgress">
                    <loading-snippet :title="$t('rent.release_in_progress', 'Releasing the powerbank...')" loading-text=""></loading-snippet>
                </div>
                <div v-if="releaseStatus">
                    <template v-if="releaseStatus === 'success'">
                        <message-snippet
                            type="success"
                            :title="$t('rent.release_status_success', '⚡ Success ⚡')"
                            :loading-text="$t('rent.release_status_slot', 'Power bank has been released. Please pick it up from the slot number:')"
                            :bold-text="releaseData.slot"
                        ></message-snippet>
                    </template>
                    <h2 v-if="releaseStatus === 'error'" class="payment-title">{{ $t('rent.release_status_error', 'An error occurred.') }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MutationCreatePaymentIntent } from '@/graphql/order';
import {
    MutationCabinetBorrowPowerbank
} from "@/graphql/cabinets";
import LoadingSnippet from "@/components/common/loading-snippet/loading-snippet";
import MessageSnippet from "@/components/common/message-snippet";

export default {
    name: 'Rent',
    components: {
        LoadingSnippet,
        MessageSnippet
    },
    data() {
        return {
            isLoading: true,
            paymentInProgress: false,
            clientSecret: '',
            cabinet: {},
            releaseInProgress: false,
            releaseStatus: '',
            releaseData: {}
        }
    },
    mounted() {
        let stripeScript = document.createElement('script')
        stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
        document.head.appendChild(stripeScript)
        this.createPaymentIntent();
    },
    methods: {
        createPaymentIntent() {
            let input = {
                clientMutationId: 'mutationCreatePaymentIntent',
                cabinet_id: this.cabinetId,
            }

            this.$apollo.mutate({
                mutation: MutationCreatePaymentIntent,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log(data.data)
                if ( data.data.createPaymentIntent.status && data.data.createPaymentIntent.clientSecret ) {
                    this.clientSecret = data.data.createPaymentIntent.clientSecret;
                    this.initPayment();
                } else {
                    alert('Error: ' + data.data.createPaymentIntent.message)
                }
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
            })
        },
        initPayment() {
            let _vm = this;
            let stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
            let elements = stripe.elements();
            let card = elements.create("card", {
                hidePostalCode: true,
                style: this.stripeStyle
            });
            // Stripe injects an iframe into the DOM
            card.mount("#card-element");
            card.on("change", function (event) {
                // Disable the Pay button if there are no card details in the Element
                document.querySelector("button").disabled = event.empty;
                document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
            });
            let form = document.getElementById("payment-form");
            form.addEventListener("submit", function(event) {
                event.preventDefault();
                // Complete payment when the submit button is clicked
                _vm.payWithCard(stripe, card, _vm.clientSecret);
            });
            console.log(stripe)
        },
        payWithCard(stripe, card, clientSecret) {
            let _vm = this;
            this.paymentInProgress = true;
            stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: card
                    }
                })
                .then(function(result) {
                    console.log('result ->? >>> ', result)
                    if (result.error) {
                        // Show error to your customer
                        _vm.orderError(result.error.message);
                    } else {
                        // The payment succeeded!
                        _vm.orderComplete(result.paymentIntent.id);
                    }
                });
        },
        orderComplete(paymentIntentId) {
            document
                .querySelector(".result-message a")
                .setAttribute(
                    "href",
                    "https://dashboard.stripe.com/test/payments/" + paymentIntentId
                );
            document.querySelector(".result-message").classList.remove("hidden");
            document.querySelector("button").disabled = true;
            this.paymentInProgress = false;
            this.borrowPowerbank(paymentIntentId)
        },
        orderError(message) {
            let errorMsg = document.querySelector("#card-error");
            errorMsg.textContent = message;
            setTimeout(function() {
                errorMsg.textContent = "";
            }, 4000);
            this.paymentInProgress = false;
        },
        borrowPowerbank(paymentIntentId) {
            let cabinetId = this.cabinetId;
            let input = {
                clientMutationId: 'mutationCabinetBorrowPowerbankId',
                cabinet_id: cabinetId,
                payment_intent_id: paymentIntentId,
            }
            this.releaseInProgress = true;
            this.$apollo.mutate({
                mutation: MutationCabinetBorrowPowerbank,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log('Borrow POWERBANK [APP] -> ', data)
                this.releaseData = data.data.cabinetBorrowPowerbank;
                this.releaseStatus = 'success';
                this.releaseData.slot = Math.floor(Math.random() * 12) + 1;
                // TODO: Fix this
                // if( data.data.cabinetBorrowPowerbank.status == 1 ) {
                //     this.releaseStatus = 'success';
                // } else {
                //     this.releaseStatus = 'error';
                // }
                this.releaseInProgress = false;
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
                this.releaseInProgress = false;
            })
        },
    },
    computed: {
        cabinetId() {
            return this.$route.params.id;
        },
        isUserLoggedIn() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        stripeStyle() {
            return {
                base: {
                    color: "#0F172A",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#0F172A"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#B45309",
                    iconColor: "#B45309"
                }
            }
        },
        loaderUrl() {
            return require('../../assets/icons/loader.gif');
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/order.scss';
</style>