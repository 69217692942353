import gql from 'graphql-tag';

export const GetCabinetsForAdmin = gql`query getCabinets( $first: Int, $after: String, $last: Int, $before: String ){
  cabinets(after: $after, before: $before, first: $first, last: $last) {
    edges {
      cursor
      node {
        address
        cabinet_id
        db_id
        id
        port
        status
        timestamp
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}`;


// Update profile data
export const MutationCabinetForcePop = gql`
  mutation cabinet_force_pop($input: CabinetForcePopInput!) {
    cabinetForcePop(input: $input) {
        status
        message
    }
}
`;

// Update profile data
export const MutationCabinetBorrowPowerbank = gql`
  mutation cabinet_borrow_powerbank($input: CabinetBorrowPowerbankInput!) {
    cabinetBorrowPowerbank(input: $input) {
        status
        message
    }
}
`;

// Query inventory
export const MutationCabinetQueryInventory = gql`
  mutation cabinet_query_inventory($input: CabinetQueryInventoryInput!) {
    cabinetQueryInventory(input: $input) {
        status
        message
    }
}
`;