<template>
    <div class="cabinets-list">
        <h2>Cabinets List</h2>

        <table class="table-auto">
            <thead>
            <tr>
                <th class="w-1/12 px-4 py-2">ID</th>
                <th v-if="isAdmin" class="w-2/9 px-4 py-2">Cabinet ID</th>
                <th class="w-4/12 px-4 py-2">Address</th>
                <th class="w-1/12 px-4 py-2">Status</th>
                <th class="w-2/12 px-4 py-2">Last seen</th>
                <th class="w-2/12 px-4 py-2">Inventory</th>
                <th class="w-2/12 px-4 py-2">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(cabinet, index) of cabinets">
                <td class="px-4 py-2">{{ cabinet.db_id }}</td>
                <td v-if="isAdmin" class="w-1/4 px-4 py-2">{{ cabinet.cabinet_id }}</td>
                <td class="px-4 py-2">{{ cabinet.street_address }}</td>
                <td class="px-4 py-2 text-center">
                    <span v-if="cabinet.is_online == 1" class="text-green-700">Online</span>
                    <span v-else class="text-yellow-500">Offline</span>
                </td>
                <td class="px-4 py-2 text-center">
                    <div v-if="cabinet.last_seen" class="whitespace-no-wrap overflow-x-auto">{{ convertUnixTimestampToTime(cabinet.last_seen) }}</div>
                </td>
                <td class="px-4 py-2 text-center">
                    <div class="flex flex-col">
                        <span class="text-gray-900">Total: {{ cabinet.total_slots }}</span>
                        <span class="text-blue-700">Borrow: {{ cabinet.available_powerbanks }}</span>
                        <span class="text-purple-700">Return: {{ cabinet.available_slots }}</span>
                    </div>
                </td>
                <td class="px-4 py-2 text-center">
                    <button
                       @click.prevent="borrowPowerbank(cabinet.db_id)"
                       :disabled="cabinet.is_online != 1"
                       class="block mb-2 border rounded px-4 py-2 text-sm"
                    >Borrow (success)</button>
                    <button href="#"
                       @click.prevent="returnPowerbank(cabinet.db_id)"
                       :disabled="cabinet.is_online != 1"
                       class="block mb-2 border rounded px-4 py-2 text-sm"
                    >Return (success)</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { GetAvailableCabinets } from "../../../graphql/cabinets";
import { MutationCabinetBorrowPowerbank } from "@/graphql/cabinets";

export default {
    name: 'CabinetsList',
    props: [
        'isAdmin'
    ],
    data() {
        return {
            cabinets: []
        }
    },
    mounted() {
        this.getCabinets();
    },
    methods: {
        getCabinets() {
            this.$apollo.query({
                query: GetAvailableCabinets
            }).then((data) => {
                if (data.data.cabinets.edges.length) {
                    for (let i = 0; i < data.data.cabinets.edges.length; i++) {
                        this.cabinets.push(data.data.cabinets.edges[i].node);
                        console.log(data.data.cabinets.edges[i].node)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        convertUnixTimestampToTime(timestamp) {
            let date = new Date(timestamp * 1000);
            let humanTime = [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-') + ' ' + [date.getHours(), date.getMinutes(), date.getSeconds()].join(':');

            return humanTime;
        },
        borrowPowerbank(db_id, slot) {
            let input = {
                clientMutationId: 'mutationCabinetBorrowPowerbankId',
                db_id: db_id,
                slot: slot,
            }
            this.$apollo.mutate({
                mutation: MutationCabinetBorrowPowerbank,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log('Borrow POWERBANK [APP] -> ', data)
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
            })
        },
    }
};
</script>
