<template>
    <svg class="icon"
         xmlns="http://www.w3.org/2000/svg"
         :viewBox="getViewBox"
         v-html="getIcon"
         fill="none"
         stroke="currentColor"
         :class="className"></svg>
</template>

<script>
export default {
    name: "icon",
    data() {
        return {
            icon: null,
            icons: {
                'qrold':
                    '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />',
                'qr': '<path d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V7C21.25 4.42 19.58 2.75 17 2.75H7C4.42 2.75 2.75 4.42 2.75 7V9C2.75 9.41 2.41 9.75 2 9.75C1.59 9.75 1.25 9.41 1.25 9V7C1.25 3.56 3.56 1.25 7 1.25H17C20.44 1.25 22.75 3.56 22.75 7V9C22.75 9.41 22.41 9.75 22 9.75Z" />\n' +
                    '<path d="M17 22.75H7C3.56 22.75 1.25 20.44 1.25 17V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17C2.75 19.58 4.42 21.25 7 21.25H17C19.58 21.25 21.25 19.58 21.25 17V15C21.25 14.59 21.59 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15V17C22.75 20.44 20.44 22.75 17 22.75Z" />\n' +
                    '<path d="M22 12.75H2C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H22C22.41 11.25 22.75 11.59 22.75 12C22.75 12.41 22.41 12.75 22 12.75Z" />\n' +
                    '<path opacity="0.1" d="M16.5 9H7.5C7.22 9 7 8.78 7 8.5V6.5C7 5.68 7.67 5 8.5 5H15.5C16.33 5 17 5.68 17 6.5V8.5C17 8.78 16.78 9 16.5 9Z" />\n' +
                    '<path opacity="0.1" d="M16.5 15H7.5C7.22 15 7 15.22 7 15.5V17.5C7 18.32 7.67 19 8.5 19H15.5C16.33 19 17 18.32 17 17.5V15.5C17 15.22 16.78 15 16.5 15Z" />',
                'map': '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />',
                'user': '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />',
                'profile':
                    '<path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" />\n' +
                    '<path d="M17.0796 14.1499C14.2896 12.2899 9.73963 12.2899 6.92963 14.1499C5.65963 14.9999 4.95963 16.1499 4.95963 17.3799C4.95963 18.6099 5.65963 19.7499 6.91963 20.5899C8.31963 21.5299 10.1596 21.9999 11.9996 21.9999C13.8396 21.9999 15.6796 21.5299 17.0796 20.5899C18.3396 19.7399 19.0396 18.5999 19.0396 17.3599C19.0296 16.1299 18.3396 14.9899 17.0796 14.1499Z" />',
                'return': '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />',
                'borrow': '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />',
                'locationold':
                    '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />',
                'location': '<path d="M12.0001 13.43C13.7232 13.43 15.1201 12.0332 15.1201 10.31C15.1201 8.58691 13.7232 7.19004 12.0001 7.19004C10.277 7.19004 8.88008 8.58691 8.88008 10.31C8.88008 12.0332 10.277 13.43 12.0001 13.43Z" stroke-width="1.5"/>\n' +
                    '<path d="M3.61988 8.49C5.58988 -0.17 18.4199 -0.159999 20.3799 8.5C21.5299 13.58 18.3699 17.88 15.5999 20.54C13.5899 22.48 10.4099 22.48 8.38988 20.54C5.62988 17.88 2.46988 13.57 3.61988 8.49Z" stroke-width="1.5"/>',
                'card': '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />',
                'menu':
                    '<path d="M3 7H21" stroke-width="1.5" stroke-linecap="round"/>\n' +
                    '<path d="M3 12H21" stroke-width="1.5" stroke-linecap="round"/>\n' +
                    '<path d="M3 17H21" stroke-width="1.5" stroke-linecap="round"/>',
                'close': '<path d="M5 5L19 19" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.00001 19L19 5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>',
                'map2': '<path d="M2.28998 7.77998V17.51C2.28998 19.41 3.63998 20.19 5.27998 19.25L7.62998 17.91C8.13998 17.62 8.98998 17.59 9.51998 17.86L14.77 20.49C15.3 20.75 16.15 20.73 16.66 20.44L20.99 17.96C21.54 17.64 22 16.86 22 16.22V6.48998C22 4.58998 20.65 3.80998 19.01 4.74998L16.66 6.08998C16.15 6.37998 15.3 6.40998 14.77 6.13998L9.51998 3.51998C8.98998 3.25998 8.13998 3.27998 7.62998 3.56998L3.29998 6.04998C2.73998 6.36998 2.28998 7.14998 2.28998 7.77998Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.56 4V17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.73 6.62012V20.0001" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
                'route': '<path d="M2.06999 4.59988C2.86999 1.13988 8.07999 1.13988 8.86999 4.59988C9.33999 6.62988 8.04999 8.34988 6.92999 9.41988C6.10999 10.1999 4.81999 10.1899 3.99999 9.41988C2.88999 8.34988 1.59999 6.62988 2.06999 4.59988Z" stroke-width="1.5"/><path d="M15.07 16.5999C15.87 13.1399 21.11 13.1399 21.91 16.5999C22.38 18.6299 21.09 20.3499 19.96 21.4199C19.14 22.1999 17.84 22.1899 17.02 21.4199C15.89 20.3499 14.6 18.6299 15.07 16.5999Z" stroke-width="1.5"/><path d="M12 5H14.68C16.53 5 17.39 7.29 16 8.51L8.01001 15.5C6.62001 16.71 7.48001 19 9.32001 19H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.48622 5.5H5.49777" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.4862 17.5H18.4978" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>',
                'battery-charging':
                    '<path d="M20.5 9.5C22 9.5 22 10 22 11V13C22 14 22 14.5 20.5 14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M9.99995 8L8.10995 10.5C7.71995 11.17 8.19995 12 8.96995 12H11.27C12.04 12 12.52 12.83 12.14 13.5L9.99995 16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M7 19C3 19 2 18 2 14V10C2 6 3 5 7 5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M13 5C17 5 18 6 18 10V14C18 18 17 19 13 19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
                'profile2':
                    '<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
                'profile-logout':
                    '<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M19.0299 16.9399L16.9199 19.0499" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M16.9399 16.96L19.0599 19.0699" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>',
                'home':
                    '<path d="M12 18V15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M10.07 2.81997L3.14002 8.36997C2.36002 8.98997 1.86002 10.3 2.03002 11.28L3.36002 19.24C3.60002 20.66 4.96002 21.81 6.40002 21.81H17.6C19.03 21.81 20.4 20.65 20.64 19.24L21.97 11.28C22.13 10.3 21.63 8.98997 20.86 8.36997L13.93 2.82997C12.86 1.96997 11.13 1.96997 10.07 2.81997Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
                'success':
                    '<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '<path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
            }
        }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        className: {
            type: String,
            required: false
        },
        viewBox: {
            type: String,
            required: false
        }
    },
    computed: {
        getIcon() {
            return this.icons[this.name];
        },
        getViewBox() {
            return this.viewBox ? this.viewBox : '0 0 24 24';
        }
    },
    mounted() {
        this.icon = this.getIcon;
    }
}
</script>