<template>
    <div
        class="dapp-login__tab"
        v-if="openContent">
        <div
            class="dapp-login__tab-error"
            v-if="error">
            {{error}}
        </div>
        <template v-if="!isExtensionInstalled">
            <a :href="getDeFiExtensionUrl"
               target="_blank"
               rel="noreferrer"
               class="dapp-login__tab-link"
            >Install Maiar DeFi Extension</a>
        </template>
        <template v-else class="dapp-login__tab-info">
            <p class="dapp-login__tab-text">
                Please unlock Maiar DeFi Extension and select the wallet you want to connect.
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ErdjsLoginDefi',
    data () {
        return {
            error: null,
            openContent: false
        }
    },
    props: {
        selectedMode: {
            type: String,
            default: ''
        },
        token: {
            require: false,
            type: String
        }
    },
    watch: {
        selectedMode (selectedMode) {
            console.log("Defi", selectedMode)
            if ( selectedMode === 'Maiar DeFi Wallet' ) {
                this.login(selectedMode)
                this.openContent = true;
            } else {
                this.openContent = false;
            }
        }
    },
    methods: {
        login (name) {
            this.$emit('select-mode', name);
            const options = this.token ? { token: this.token, callbackUrl: window.location  } : {};
            this.$erd.defiWallet.login(options);
        },
        isFirefox() {
            return navigator.userAgent.indexOf('Firefox') != -1;
        }
    },
    computed: {
        isExtensionInstalled() {
            return window.elrondWallet;
        },
        getDeFiExtensionUrl() {
            return this.isFirefox
                ? 'https://addons.mozilla.org/en-US/firefox/addon/maiar-defi-wallet/'
                : 'https://chrome.google.com/webstore/detail/dngmlblcodfobpdpecaadgfbcggfjfnm?authuser=0&hl=en';
        }
    }
}
</script>
