import gql from 'graphql-tag';

export const GetCabinetById = gql`query getCabinetById( $id: ID!, $idType: String ){
  cabinet(id: $id, idType: $idType) {
    cabinet_id
    db_id
    id
    status
    lat
    lng
    street_address
    timestamp
    is_online
    available_powerbanks
    available_slots
    total_slots
    last_seen
  }
}`;

export const GetAvailableCabinets = gql`query getCabinets( $first: Int, $after: String, $last: Int, $before: String ){
  cabinets(after: $after, before: $before, first: $first, last: $last) {
    edges {
      cursor
      node {
        cabinet_id
        db_id
        id
        status
        lat
        lng
        street_address
        timestamp
        is_online
        available_powerbanks
        available_slots
        total_slots
        last_seen
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}`;


// Update profile data
export const MutationCabinetForcePop = gql`
  mutation cabinet_force_pop($input: CabinetForcePopInput!) {
    cabinetForcePop(input: $input) {
        status
        message
    }
}
`;

// Update profile data
export const MutationCabinetBorrowPowerbank = gql`
  mutation cabinet_borrow_powerbank($input: CabinetBorrowPowerbankInput!) {
    cabinetBorrowPowerbank(input: $input) {
        status
        message
        slot
    }
}
`;

// Query inventory
export const MutationCabinetQueryInventory = gql`
  mutation cabinet_query_inventory($input: CabinetQueryInventoryInput!) {
    cabinetQueryInventory(input: $input) {
        status
        message
    }
}
`;