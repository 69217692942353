<template>
    <div class="login app-page flex flex-col ">
        <div class="app-page__wrap login__wrap w-full flex items-start justify-center px-4 sm:px-6 lg:px-8">
            <div class="max-w-md w-full tabs">
                <template v-if="!isUserLoggedIn">
                    <div class="tabs__buttons flex items-center w-full mb-6 bg-color-7">
                        <button
                            @click.prevent="tab = 'web3'"
                            :class="[
                                'grow py-2 sm:py-2 px-4 sm:px-6 font-medium text-center text-sm sm:text-base',
                                { 'bg-color-1 color-4': tab === 'web3', 'color-8': tab !== 'web3' }
                            ]"
                        >Elrond wallet</button>
                        <button
                            @click.prevent="tab = 'web2'"
                            :class="[
                                'grow py-2 sm:py-2 px-4 sm:px-6 font-medium text-center text-sm sm:text-base',
                                { 'bg-color-1 color-4': tab === 'web2', 'color-8': tab !== 'web2' }
                            ]"
                        >Email address</button>
                    </div>
                </template>
                <template v-if="tab === 'web3' && !isUserLoggedInWithEmail">
                    <template v-if="!isUserLoggedIn">
                        <div class="tabs__header py-5 my-5">
                            <h2 class="text-2xl font-bold pb-3">Sign in with Elrond wallet</h2>
                            <p class="font-light">Choose a method to login. Don't have a wallet yet?
                                <a href="https://wallet.elrond.com/create" target="_blank" ref="noreferrer noopener" class="link">Click here to create one.</a></p>
                        </div>
                    </template>
                    <div class="tabs__content tabs__content-bg shadow-lg rounded-lg max-w-md w-full space-y-5">
                        <DappLoginTab />
                    </div>
                </template>
                <template v-if="tab === 'web2'">
                    <LoginCard v-if="web2tab === 'login'" @action="changeTab($event)"></LoginCard>
                    <RegisterCard v-if="web2tab === 'register'" @action="changeTab($event)"></RegisterCard>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { USER_GET_LOGGED_IN } from "../../store/modules/user";
import DappLoginTab from '@/dapp/parts/login-tab'
import LoginCard from "./login-card";
import RegisterCard from "./register-card";

export default {
    name: 'Login',
    components: {
        LoginCard,
        RegisterCard,
        DappLoginTab
    },
    data () {
        return {
            tab: 'web3',
            web2tab: 'login',
            isLoading: false
        }
    },
    mounted() {
        if( this.isUserLoggedInWithEmail ) {
            this.tab = 'web2';
        }
    },
    computed: {
        isUserLoggedIn() {
            let loggedIn = this.isUserLoggedInWithEmail;
            if( !loggedIn ) {
                return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
            }

            return loggedIn;
        },
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
    },
    methods: {
        changeTab(event) {
            this.web2tab = event;
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/login.scss';
</style>
