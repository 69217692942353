<template>
    <div :class="['message-snippet', 'message-snippet--type-' + type]">
        <template v-if="type === 'success'">
            <div class="message-snippet--icon">
                <Icon name="success" class-name="icon-success"/>
            </div>
        </template>
        <template v-if="type === 'onboarding'">
            <onboarding-icon class="mb-10 onboarding-icon"></onboarding-icon>
        </template>
        <template v-if="type === 'logo'">
            <div class="message-snippet--icon message-snippet--icon-logo">
                <svg width="200" height="200" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="505.077" cy="504.03" r="318.942" fill="url(#paint0_linear_0_3)"/>
                    <circle cx="505.077" cy="504.03" r="318.942" fill="url(#paint1_linear_0_3)"/>
                    <path d="M650.339 124L299.385 444.897C247.717 492.14 256.497 575.898 316.839 611.399C371.088 643.316 441.033 620.146 465.498 562.153L650.339 124Z" fill="url(#paint2_linear_0_3)"/>
                    <path d="M370.135 875.316L561.168 439.827C589.292 375.713 671.425 357.1 724.441 402.825C772.105 443.933 772.651 517.614 725.602 559.425L370.135 875.316Z" fill="url(#paint3_linear_0_3)"/>
                    <defs>
                        <linearGradient id="paint0_linear_0_3" x1="395.779" y1="544.906" x2="242.882" y2="790.577" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0057FF" stop-opacity="0"/>
                            <stop offset="1" stop-color="#0057FF"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_0_3" x1="641.212" y1="-41.8915" x2="385.894" y2="392.092" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0057FF"/>
                            <stop offset="1" stop-color="#0057FF" stop-opacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_0_3" x1="494.428" y1="356.967" x2="670.585" y2="98.1869" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00FFC4"/>
                            <stop offset="1" stop-color="#100D2C"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_0_3" x1="540.61" y1="652.784" x2="352.201" y2="902.786" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00FFC4"/>
                            <stop offset="1" stop-color="#100D2C"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </template>
        <h2 class="message-snippet--title" v-if="title" v-html="title"></h2>
        <h2 class="message-snippet--subtitle" v-if="subtitle" v-html="subtitle"></h2>
        <div class="message-snippet--text" v-html="loadingText"></div>
        <div class="message-snippet--bold-text" v-if="boldText" v-html="boldText"></div>
        <div class="message-snippet--button" v-if="routerLink.name || routerLink.path">
            <router-link
                :to="routerLink"
                :class="[routerLinkProps.class]"
            >{{ routerLinkProps.text }}</router-link>
        </div>
        <div class="message-snippet--button" v-if="buttonText">
            <button @click.prevent="$emit('message-snippet-button-click' )" :class="[buttonClass]">{{ buttonText }}</button>
        </div>
        <div class="message-snippet--link" v-if="linkHref">
            <a :href="linkHref" :class="[linkClass]" target="_blank">{{ linkText }}</a>
        </div>
    </div>
</template>

<script>
import OnboardingIcon from "@/components/common/onboarding-icon"
import Icon from "./icon";

    export default {
        name: 'MessageSnippet',
        props: {
            type: {
                type: String,
                default: 'success'
            },
            title: {
                type: String,
                default: ''
            },
            subtitle: {
                type: String,
                default: ''
            },
            loadingText: {
                type: String,
                default: 'loading...'
            },
            boldText: {
                type: [Number, String],
                default: ''
            },
            routerLink: {
                type: Object,
                default: function () {
                    return {
                        name: null,
                            path: null,
                    }
                }
            },
            routerLinkProps: {
                type: Object,
                default: function() {
                    return {
                        text: ''
                    }
                }
            },
            buttonText: {
                type: String,
                default: ''
            },
            buttonClass: {
                type: String,
                default: ''
            },
            linkHref: {
                type: String,
                default: ''
            },
            linkText: {
                type: String,
                default: ''
            },
            linkClass: {
                type: String,
                default: ''
            },
        },
        components: {
            Icon,
            OnboardingIcon
        }
    };
</script>

<style lang="scss">
@import '@/assets/scss/message-snippet.scss';
</style>
