export const SVG = {
    'powerbank': '<svg id="Capa_1" enable-background="new 0 0 510 510" height="64" viewBox="0 0 510 510" width="64" xmlns="http://www.w3.org/2000/svg"><g><path' +
        ' d="m237.316 185.263h-97.506l57.163-81.661c4.75-6.786 3.1-16.14-3.687-20.89-6.788-4.752-16.14-3.101-20.891 3.687l-73.684 105.262c-6.934 9.906.146 23.602 12.289 23.602h98.288l-47.611 71.417c-4.595 6.893-2.732 16.206 4.161 20.801 6.862 4.575 16.185 2.764 20.801-4.16l63.158-94.737c6.63-9.947-.509-23.321-12.481-23.321z"/><path d="m486 161.738v-50.738c0-8.284-6.716-15-15-15h-64c-8.284 0-15 6.716-15 15v50.738c-4.756 2.516-8 7.507-8 13.262v49.361c0 24.864 17.009 45.826 40 51.876v136.723c0 36.966-27.01 67.04-60.21 67.04h-113.58c-23.809 0-49.154-12.673-57.419-34h100.677c22.35 0 40.532-18.183 40.532-40.532v-364.936c0-22.349-18.183-40.532-40.532-40.532h-236.936c-22.349 0-40.532 18.183-40.532 40.532v364.936c0 22.349 18.183 40.532 40.532 40.532h105.021c8.329 39.488 48.714 64 88.658 64h113.58c49.742 0 90.21-43.532 90.21-97.04v-136.723c22.991-6.05 40-27.012 40-51.876v-49.361c-.001-5.754-3.245-10.746-8.001-13.262zm-429.468 254.262c-5.807 0-10.532-4.725-10.532-10.532v-364.936c0-5.807 4.725-10.532 10.532-10.532h236.936c5.807 0 10.532 4.725 10.532 10.532v364.936c0 5.808-4.725 10.532-10.532 10.532zm365.468-290h34v34h-34zm42 98.361c0 13.035-10.604 23.639-23.639 23.639h-2.723c-13.034 0-23.638-10.604-23.638-23.639v-34.361h50z"/><path d="m223 352h-96c-8.284 0-15 6.716-15 15s6.716 15 15 15h96c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/></g></svg>',
    'powerbank-out': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1 16.44C14.79 20.04 12.94 21.51 8.89001 21.51H8.76001C4.29001 21.51 2.50001 19.72 2.50001 15.25V8.74C2.50001 4.27 4.29001 2.48 8.76001 2.48H8.89001C12.91 2.48 14.76 3.93 15.09 7.47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 12H20.38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.15 15.35L21.5 12L18.15 8.64999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'powerbank-in': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1 16.44C14.79 20.04 12.94 21.51 8.89 21.51H8.76C4.29 21.51 2.5 19.72 2.5 15.25L2.5 8.72999C2.5 4.25999 4.29 2.46999 8.76 2.46999H8.89C12.91 2.46999 14.76 3.91999 15.09 7.45999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 12L9.12 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.35 15.35L8 12L11.35 8.65001" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'route': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.93 19.4C21.13 22.86 15.92 22.86 15.13 19.4C14.66 17.37 15.95 15.65 17.07 14.58C17.89 13.8 19.18 13.81 20 14.58C21.11 15.65 22.4 17.37 21.93 19.4Z" stroke-width="1.5"/>
        <path d="M8.93001 7.4C8.13001 10.86 2.89001 10.86 2.09001 7.4C1.62001 5.37 2.91001 3.65 4.04001 2.58C4.86001 1.8 6.16 1.81 6.98 2.58C8.11 3.65 9.40001 5.37 8.93001 7.4Z" stroke-width="1.5"/>
        <path d="M12 19H9.31999C7.46999 19 6.60999 16.71 7.99999 15.49L15.99 8.5C17.38 7.29 16.52 5 14.68 5H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.5138 18.5H18.5022" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.51378 6.5H5.50223" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'flash-slash': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.18005 18.04V20.48C9.18005 22.16 10.0901 22.5 11.2001 21.24L18.7701 12.64C19.7001 11.59 19.3101 10.72 17.9001 10.72H16.9701" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.82 8.84002V3.52002C14.82 1.84002 13.91 1.50002 12.8 2.76002L5.23 11.36C4.3 12.41 4.69 13.28 6.1 13.28H9.19V14.46" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 2L2 22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'flash': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.08998 13.28H9.17998V20.48C9.17998 22.16 10.09 22.5 11.2 21.24L18.77 12.64C19.7 11.59 19.31 10.72 17.9 10.72H14.81V3.52002C14.81 1.84002 13.9 1.50002 12.79 2.76002L5.21998 11.36C4.29998 12.42 4.68998 13.28 6.08998 13.28Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'bitcoin': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 8.38086H13.6846C14.7231 8.38086 15.5654 9.31548 15.5654 10.2616C15.5654 11.3001 14.7231 12.1424 13.6846 12.1424H9V8.38086Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 12.1309H14.3539C15.5423 12.1309 16.5 12.9732 16.5 14.0116C16.5 15.0501 15.5423 15.8924 14.3539 15.8924H9V12.1309Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.2769 15.8809V17.7616" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.93457 15.8809V17.7616" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.2769 6.5V8.38077" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.93457 6.5V8.38077" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.7769 8.38086H7.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.7769 15.8809H7.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="1.5" stroke-miterlimit="10"/>
        </svg>
    `,
    'credit-card': `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 12.6101H19" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.25 17.8101H6.96997" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.10986 17.8101H12.5499" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    'clock': '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
        '</svg>',
    'price': '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
        '</svg>',
}