<template>
    <div class="loading-snippet">
        <h2 class="loading-snippet--title" v-if="title">{{ title }}</h2>
        <div class="battery">
            <div class="top"></div>
            <div class="charge1"></div>
            <div class="charge2"></div>
            <div class="charge3"></div>
            <div class="charge4"></div>
        </div>
        <div class="loading-snippet--text">{{ loadingText }}</div>
        <div class="message-snippet--link" v-if="linkHref">
            <a :href="linkHref" :class="[linkClass]" target="_blank">{{ linkText }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingSnippet',
        props: {
            title: {
                type: String,
                default: ''
            },
            loadingText: {
                type: String,
                default: ''
            },
            linkHref: {
                type: String,
                default: ''
            },
            linkText: {
                type: String,
                default: ''
            },
            linkClass: {
                type: String,
                default: ''
            },
        }
    };
</script>

<style lang="scss">
@import '@/assets/scss/common.scss';
</style>
