<template>
    <div class="register__card">
        <template v-if="isUserLoggedIn">
            <h3 class="mb-6 text-center text-xl font-bold text-gray-900">{{ getGreetingText }}</h3>
            <div v-if="userData.email" class="text-center mb-6">
                <span class="mr-2">You're logged in with email address: </span>
                <strong>{{ userData.email }}</strong>
            </div>
            <div class="flex flex-row justify-center">
                <button
                    @click.prevent="logout()"
                    class="relative py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-2"
                >Logout</button>
            </div>
        </template>
        <template v-else>
            <div class="tabs__header py-5 my-5">
                <h2 class="text-2xl font-bold pb-3">Sign up</h2>
                <p class="font-light">Please enter your email address and choose a strong password to create a new account.</p>
            </div>

            <div class="tabs__content max-w-md w-full space-y-5">
                <form class="form mt-8 space-y-6" @submit.prevent="registerUser()">
                    <input type="hidden" name="remember" value="true" />

                    <div class="form__row">
                        <label for="email-address" class="form__label">Email address</label>
                        <input id="email-address" class="form__input relative block w-full px-3 py-2 sm:text-sm"
                               v-model="user_data.email"
                               name="email"
                               type="email"
                               autocomplete="email"
                               placeholder="Email address" />
                    </div>
                    <div class="form__row">
                        <label for="password" class="form__label">Password</label>
                        <input id="password" class="form__input relative block w-full px-3 py-2 sm:text-sm"
                               v-model="user_data.password"
                               name="password"
                               type="password"
                               autocomplete="new-password"
                               required=""
                               placeholder="Password" />
                    </div>
                    <div class="form__row">
                        <label for="password2" class="form__label">Password repeat</label>
                        <input id="password2"
                               class="form__input relative block w-full px-3 py-2 sm:text-sm"
                               v-model="user_data.password_repeat"
                               name="password2"
                               type="password"
                               autocomplete="new-password"
                               required=""
                               placeholder="Password repeat" />
                    </div>

                    <div class="flex items-center justify-between text-sm">
                        <a href="#" @click.prevent="$emit('action', 'login')" class="font-light link">
                            Sign in
                        </a>
                        <a href="#" class="font-light link">
                            Forgot your password?
                        </a>
                    </div>

                    <div>
                        <button @click.prevent="registerUser" type="submit"
                                 class="group relative w-full flex justify-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-2">
                            <div v-if="isLoading" class="spinner-border animate-spin inline-block w-4 h-4 border-4 border-color-1 rounded-full color-1" role="status"></div>
                            <span>Sign Up</span>
                        </button>
                    </div>
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import { MutationRegisterUser } from '@/graphql/auth';
import {
    USER_SET_AUTH_TOKEN,
    USER_SET_LOGGED_IN,
    USER_SET_REFRESH_TOKEN
} from '@/store/modules/user';
import {USER_GET_LOGGED_IN, USER_GET_PROFILE_DATA, USER_LOGOUT} from "../../store/modules/user";

export default {
    name: 'RegisterCard',
    data () {
        return {
            user_data: {
                email: '',
                password: '',
                password_repeat: '',
            },
            validator: {
                has_errors: false,
                message: ''
            },
            isLoading: false
        }
    },
    methods: {
        registerUser() {
            this.isLoading = true;
            this.validateFields();
            if (!this.validator.has_errors) {
                let username = this.user_data.email.replace('@', '_');
                username = username.replace('+', '_');
                username = username.replace('.', '_');
                let input = {
                    clientMutationId: 'mutationRegisterUserId',
                    username: username,
                    email: this.user_data.email,
                    password: this.user_data.password,
                }

                this.$apollo.mutate({
                    mutation: MutationRegisterUser,
                    variables: {
                        input: input,
                    },
                }).then((data) => {
                    console.log(data.data.registerUser.user)
                    if ( data.data.registerUser.user.jwtAuthToken ) {
                        this.$store.commit( 'user/' + USER_SET_AUTH_TOKEN, data.data.registerUser.user.jwtAuthToken );
                        this.$store.commit( 'user/' + USER_SET_REFRESH_TOKEN, data.data.registerUser.user.jwtRefreshToken );
                        this.$store.commit( 'user/' + USER_SET_LOGGED_IN, true );
                        this.$router.push({ name: 'dashboard' });
                        this.$root.$emit('userLoginStatusChanged', true);
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    // Error
                    console.log('error')
                    console.log(error)
                    alert(error.message.replace('GraphQL error:', ''));
                    this.isLoading = false;
                })
            } else {
                this.isLoading = false;
                alert(this.validator.message)
            }
        },
        validateFields() {
            this.$set(this.validator, 'has_errors', false);
            this.$set(this.validator, 'message', '');
            if (!this.user_data.email.trim()) {
                this.$set(this.validator, 'has_errors', true);
                this.$set(this.validator, 'message', 'Email address cannot be empty.');

                return false;
            }
            if (!this.user_data.password.trim()) {
                this.$set(this.validator, 'has_errors', true);
                this.$set(this.validator, 'message', 'Password field cannot be empty.');
            }
            if (this.user_data.password != this.user_data.password_repeat) {
                this.$set(this.validator, 'has_errors', true);
                this.$set(this.validator, 'message', 'Passwords does not match.');
            }
        },
        logout() {
            this.$erd.logout();
            this.$store.dispatch( 'user/' + USER_LOGOUT );
        },
    },
    computed: {
        isUserLoggedIn() {
            let loggedIn = this.isUserLoggedInWithEmail;
            if( !loggedIn ) {
                return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
            }

            return loggedIn;
        },
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        userData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        getGreetingText() {
            if( this.userData.name ) {
                return `Howdy, ${this.userData.name}!`;
            }

            return 'Howdy!';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/login.scss';
</style>
