<template>
    <div class="rent-wrap max-w-md mx-auto">
        <div class="rent-form-wrap">
            <div class="flex flex-col">
                <div v-show="isLoading">
                    <loading-snippet></loading-snippet>
                </div>

                <template v-if="!transactionInProgress && !transactionStatus">
                    <message-snippet
                        type="message"
                        title="Pay with eGLD"
                        loading-text="Sign transaction on Elrond blockchain and once it's confirmed by our Smart Contract, a fully charged power bank will be released from our station"
                        bold-text=""
                        button-text="Sign transaction"
                        button-class="relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-2"
                        @message-snippet-button-click="signAndWatchTransaction()"
                    ></message-snippet>
                </template>

                <template v-if="transactionInProgress && !transactionStatus">
                    <loading-snippet
                        :loading-text="$t('rent.waiting_for_transaction', 'Waiting for transaction to be processed by blockchain...')"
                    ></loading-snippet>
                    <loading-snippet v-if="transactionExplorerLink"
                         :loading-text="$t('rent.waiting_for_transaction', 'Transaction is pending...')"
                         :link-href="transactionExplorerLink"
                         link-text="View on elrond explorer"
                         link-class="relative flex justify-center py-2 px-4 mt-5 border text-sm font-medium rounded-md color-4 border-color-4"
                    ></loading-snippet>

                </template>

                <template v-if="transactionStatus">
                    <message-snippet
                        :type="transactionStatus === 'success' ? 'success' : 'message'"
                        :title="transactionStatus === 'success' ? $t('rent.release_status_success', '⚡ Success ⚡') : 'Failed'"
                        :loading-text="transactionStatus === 'success' ? $t('rent.release_status_slot',
                            'Power bank has been released. Please pick it up from the slot number:') : 'An error occurred. Please try again.'"
                        :bold-text="getSlotNumber()"
                        :link-href="transactionExplorerLink"
                        link-text="View on elrond explorer"
                        link-class="relative flex justify-center py-2 px-4 mt-5 border text-sm font-medium rounded-md color-4 border-color-4"
                    ></message-snippet>
                </template>

                <div v-if="releaseInProgress">
                    <loading-snippet :title="$t('rent.release_in_progress', 'Releasing the powerbank...')" loading-text=""></loading-snippet>
                </div>
                <div v-if="releaseStatus">
                    <template v-if="releaseStatus === 'success'">
                        <message-snippet
                            type="success"
                            :title="$t('rent.release_status_success', '⚡ Success ⚡')"
                            :loading-text="$t('rent.release_status_slot', 'Power bank has been released. Please pick it up from the slot number:')"
                            :bold-text="releaseData.slot"
                        ></message-snippet>
                    </template>
                    <h2 v-if="releaseStatus === 'error'" class="payment-title">{{ $t('rent.release_status_error', 'An error occurred.') }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MutationCabinetBorrowPowerbank
} from "@/graphql/cabinets";
import LoadingSnippet from "@/components/common/loading-snippet/loading-snippet";
import MessageSnippet from "@/components/common/message-snippet";
import {USER_GET_LOGGED_IN} from "../../store/modules/user";

import { Address, Balance, ContractFunction, GasLimit,
    TransactionPayload, Account, Transaction, TransactionStatus } from "@elrondnetwork/erdjs";
import {TransactionWatcher} from "@elrondnetwork/erdjs/out/transactionWatcher";

export default {
    name: 'ErdjsRent',
    components: {
        LoadingSnippet,
        MessageSnippet
    },
    data() {
        return {
            isLoading: true,
            paymentInProgress: false,
            clientSecret: '',
            cabinet: {},
            releaseInProgress: false,
            releaseStatus: '',
            releaseData: {},
            transactionInProgress: false,
            transactionExplorerLink: null,
            transactionStatus: null,
        }
    },
    mounted() {
        if( this.isUserLoggedInWithElrond ) {
            this.isLoading = false;
        }
    },
    created() {
        this.$erd.$on('transaction', (transaction) => {
            console.log("transaction", transaction);

            const trans = transaction[0];
            if (!trans.status.isSuccessful()) {
                console.log(`Transaction failed : ${trans.getSmartContractResults().getImmediate().getReturnMessage()}`);
                // alert(`Transaction failed : ${trans.getSmartContractResults().getImmediate().getReturnMessage()}`);
                this.transactionExplorerLink = `https://devnet-explorer.elrond.com/transactions/${trans.hash.toString()}`
                this.transactionStatus = trans.status.toString();
                this.transactionInProgress = false;
            } else if( trans.status.isSuccessful() ) {
                this.transactionExplorerLink = `https://devnet-explorer.elrond.com/transactions/${trans.hash.toString()}`
                this.transactionStatus = trans.status.toString();
                console.log('transactionStatus', trans.getSmartContractResults().getImmediate().getReturnCode())
                this.transactionInProgress = false;
            }
        })

        this.$erd.providers.handleTransaction(transaction => {
            console.log('++ + ++ + ++ +', transaction)
        })
    },
    methods: {
        borrowPowerbank(paymentIntentId) {
            let cabinetId = this.cabinetId;
            let input = {
                clientMutationId: 'mutationCabinetBorrowPowerbankId',
                cabinet_id: cabinetId,
                payment_intent_id: paymentIntentId,
            }
            this.releaseInProgress = true;
            this.$apollo.mutate({
                mutation: MutationCabinetBorrowPowerbank,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log('Borrow POWERBANK [APP] -> ', data)
                this.releaseData = data.data.cabinetBorrowPowerbank;
                if( data.data.cabinetBorrowPowerbank.status == 1 ) {
                    this.releaseStatus = 'success';
                } else {
                    this.releaseStatus = 'error';
                }
                this.releaseInProgress = false;
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
                this.releaseInProgress = false;
            })
        },
        signAndWatchTransaction() {
            this.signTransaction();
        },
        async signTransaction() {
            console.log('start');
            let amount = 0.01;
            let smartContractAddress = "erd1qqqqqqqqqqqqqpgqsn5jmnws9xvupus6na07rp0ztawstz2tet5swvm982";
            let account = new Account(this.$erd.walletAddress);
            account
                .sync(this.$erd.providers.proxy)
                .then(() => {
                    const payload = TransactionPayload.contractCall()
                        .setFunction(new ContractFunction("powerBankRent"))
                        .setArgs([])
                        .build();

                    const transaction = new Transaction({
                        sender: this.$erd.walletAddress,
                        receiver: new Address(smartContractAddress),
                        gasLimit: new GasLimit(10000000),
                        value: Balance.egld(amount),
                        data: payload,
                    });
                    transaction.setNonce(account.nonce);

                    // this.$erd.providers.sendAndWatch(transaction).then()
                    this.$erd.providers.signAndSend(transaction)
                        .then(transaction => {
                            console.log('transaction in progress')
                            this.transactionInProgress = true;
                            let hash = transaction.getHash().toString();
                            this.watch(hash);
                            this.$erd.providers.transactionResult(transaction);
                        })
                        .catch(reason => {
                            alert(reason.toString());
                        });

                });
        },
        async watch(hash) {
            let watcher = new TransactionWatcher(hash, this.$erd.proxy);
            watcher.awaitPending().then((onStatusReceived) => {
                console.log('onStatusReceived - pending', onStatusReceived)
                this.transactionExplorerLink = `https://devnet-explorer.elrond.com/transactions/${hash}`
            });
        },
        getSlotNumber() {
            return Math.floor(Math.random() * 12) + 1;
        }
    },
    computed: {
        cabinetId() {
            return this.$route.params.id;
        },
        loaderUrl() {
            return require('../../assets/icons/loader.gif');
        },
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        isUserLoggedInWithElrond() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/order.scss';
</style>