<template>
    <div class="cabinets-list container mx-auto">
        <CabinetsList :is-admin="isAdmin"></CabinetsList>
    </div>
</template>

<script>
import CabinetsList from "./list/list";
import { USER_GET_PROFILE_DATA, USER_GET_LOGGED_IN } from "../../store/modules/user";

export default {
    name: 'Cabinets',
    components: {
        CabinetsList
    },
    computed: {
        isUserLoggedIn() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        userProfileData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        isAdmin() {
            return this.userProfileData.isAdmin;
        }
    }
};
</script>
