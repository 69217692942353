import { render, staticRenderFns } from "./erdjs-login-web-wallet.vue?vue&type=template&id=47141826&"
import script from "./erdjs-login-web-wallet.vue?vue&type=script&lang=js&"
export * from "./erdjs-login-web-wallet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports