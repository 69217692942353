<template>
    <div class="scan app-page flex flex-col">
        <div class="app-page__wrap scan__wrap w-full flex items-start justify-center p-0">
            <div class="max-w-md w-full h-full scan__wrap-container">
            <template v-if="!isNativePlatform">
                <message-snippet
                    type="info"
                    title="Rent power bank"
                    loading-text="For testing purpose, let's say you are in front of the station displayed on the image below. Use the the following station ID: 000101"
                    bold-text=""
                ></message-snippet>
                <div class="station-demo mb-6">
                    <img src="@/assets/images/station_with_number_and_arrow.png" alt="Power bank station - six digits number" class="station-demo__img" />
                </div>
                <form class="station-form" action="" @submit.prevent="displayStationPage">
                    <label for="station-form__input">{{ $t('scan.label', 'Please enter the 6 digits number displayed on the station.') }}</label>
                    <input type="tel" v-model="stationId" class="form__input relative block w-full px-4 py-3 text-center bg-color-5 mb-5" placeholder="000001" />
                    <button type="submit" class="relative py-2 px-4 border border-transparent text-sm font-light font-medium rounded-md color-1 bg-color-2">
                        {{ $t('scan.rent', 'Rent power bank') }}
                    </button>
                </form>
            </template>
                <template v-if="isScanning">
                    <div class="qr-scan-clip"></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "../navigation/navigation";
import MessageSnippet from "@/components/common/message-snippet";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';

export default {
    name: 'Scan',
    components: {
        Navigation,
        MessageSnippet
    },
    data () {
        return {
            isNativePlatform: false,
            isScanning: false,
            scannedUrl: '',
            stationId: null
        }
    },
    beforeMount() {
        this.isNativePlatform = Capacitor.isNativePlatform();
        if (this.isNativePlatform) {
            this.prepareScan();
        }
    },
    mounted() {
        this.startScan();
    },
    deactivated() {
        if (this.isNativePlatform) {
            this.$root.$emit('isScanning', false);
            this.stopScan();
        }
    },
    beforeDestroy() {
        if (this.isNativePlatform) {
            this.$root.$emit('isScanning', false);
            this.stopScan();
        }
    },
    methods: {
        prepareScan() {
            BarcodeScanner.prepare();
        },
        async startScan() {
            if (!this.isNativePlatform) {
                return;
            }
            this.$root.$emit('isScanning', true);
            const status = await BarcodeScanner.checkPermission({ force: true });
            if (status.granted) {
                this.isScanning = true;
                BarcodeScanner.hideBackground();
                const result = await BarcodeScanner.startScan({
                    targetedFormats: ['QR_CODE']
                });

                // if the result has content
                if (result.hasContent) {
                    this.scannedUrl = result.content;
                    BarcodeScanner.showBackground();
                    BarcodeScanner.stopScan();
                    this.isScanning = false;
                    this.validateScannedUrl();
                }
            } else {
                alert('We need your permission to use your camera to be able to scan barcodes.')
            }
        },
        stopScan() {
            this.isScanning = false;
            BarcodeScanner.showBackground();
            BarcodeScanner.stopScan();
        },
        validateScannedUrl() {
            let url = new URL(this.scannedUrl)
            let path = url.pathname;
            console.log(url, path)
            if( path.includes('/cabinet/') ) {
                let cabinetId = url.pathname.replace('/cabinet/', '');
                cabinetId = cabinetId.endsWith('/') ? cabinetId.slice(0, -1) : cabinetId;
                this.$router.push({
                    name: 'cabinet',
                    params: {
                        'id': cabinetId
                    }
                })
            } else if( path.indexOf('https://c.yeunho.cn/mp/') !== -1 || path.indexOf('/mp/') === 0 ) {
                let cabinetId = path.replace('https://c.yeunho.cn/mp/', '');
                cabinetId = cabinetId.replace('/mp/', '');
                this.$router.push({
                    name: 'cabinet',
                    params: {
                        'id': cabinetId
                    }
                })
            } else if( path.indexOf('https://gocharge.tech/rent/') !== -1 || path.indexOf('/rent/') === 0 ) {
                let cabinetId = path.replace('https://gocharge.tech/rent/', '');
                cabinetId = cabinetId.replace('/rent/', '');
                this.$router.push({
                    name: 'cabinet',
                    params: {
                        'id': cabinetId
                    }
                })
            } else {
                alert('Something went wrong. If the issue persist, please contact us via support page.')
            }
        },
        displayStationPage() {
            if( parseInt( this.stationId ) < 1 ) {
                alert('Please submit the 6 digit station ID');
                return;
            }
            let cabinetId = 'SHUA062012000101';
            let submittedId = 'SHUA062012' + this.stationId;
            if( cabinetId !== submittedId ) {
                alert('Please submit a valid 6 digit station ID. You should use 000101');
                return;
            }

            this.$router.push({
                name: 'cabinet',
                params: {
                    'id': cabinetId
                }
            })
        }
    },
};
</script>
