import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import user from './modules/user';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'powerbank-app',
    storage: localStorage
});

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    plugins: [vuexPersist.plugin],
    modules: {
        user: user,
    }
});

export default store;