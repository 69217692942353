<template>
    <div class="map">
<!--        <h2>Map</h2>-->
<!--        <p>{{ coordinates }}</p>-->
<!--        <hr class="my-7">-->
<!--        <router-link :to="{name: 'cabinet', params: {id: 'SHUA062012000101'}}">Rent powerbank</router-link>-->

        <div class="gmap-wrapper">
            <gmap-map :center="map.center" :zoom="map.zoom" :options="getGmapOptions" style="width: 100%; height: 100%">
                <gmap-info-window :options="map.infoOptions" :position="map.infoWindowPos" :opened="map.infoWinOpen" @closeclick="map.infoWinOpen=false">
                </gmap-info-window>

                <gmap-marker :key="i" v-for="(m,i) in markers" :icon="map.icon" :position="m.position" :clickable="true"
                             @click="toggleLocationModal(m,i)"></gmap-marker>
            </gmap-map>
        </div>
        <template v-if="modalIsVisible !== false">
            <div class="gmap-infobox-wrap">
                <div class="gmap-infobox-background" @click="closeLocationModal()"></div>
                <div :class="['gmap-infobox-container', {'is-visible': modalIsVisible !== false}]">
                    <div class="modal-close" @click.prevent="closeLocationModal()">
                        <a href="#" @click.prevent="closeLocationModal()">
                            <svg width="16" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L6.09339 6.09339L10.1868 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2 10.1777L6.09339 14.2711L10.1868 10.1777" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                    <strong class="location-title">{{ modalData.title }}</strong>
                    <div class="address">{{ modalData.address }}<span v-if="modalData.address_comments">&nbsp;({{modalData.address_comments}})</span></div>
                    <div class="cabinet-details">
                        <div :class="['program', {'is-open': isModalLocationOpenNow(modalData)}]">
                            <div class="clock-icon mr-1" v-html="getSvg('clock')"></div>
                            <p v-if="isModalLocationOpenNow(modalData)">{{ $t('map.open', 'Location open') }}</p>
                            <p v-else>{{ $t('map.closed', 'Location closed') }}</p>
                            <span class="ml-3" v-if="modalData.todayProgram">{{ [ modalData.todayProgram.open, '-', modalData.todayProgram.close].join('') }}</span>
                        </div>
                        <div class="price">
                            <div class="price-icon mr-1" v-html="getSvg('price')"></div>
                            <span class="mr-3">{{ $t('map.price', 'Price') }}</span>
                            <span class="hour">{{ getPrice(modalData.price.hour, modalData.price.currency) }} {{ $t('map.price_per_hour', 'per hour') }}</span>
                            <span class="day">&nbsp; / {{ $t('map.price_max', 'max') }}&nbsp;{{ getPrice(modalData.price.day, modalData.price.currency) }}
                                &nbsp;{{ $t('map.price_per_day', 'per day') }}</span>
                        </div>
                        <div class="stock">
                            <div :class="['powerbank-slot powerbank-slot__borrow', 'powerbank-slot__borrow__' + modalData.stock.available_powerbanks]">
                                <div class="powerbank-icon mr-2" v-html="getSvg('powerbank-out')"></div>
                                <div class="powerbank-items">
                                    <span>{{ $t('map.take', 'Available to rent:') }}</span>&nbsp;
                                    <span><strong>{{ modalData.stock.available_powerbanks }}</strong>&nbsp;{{ $t('map.pieces', 'power banks') }}</span>
                                </div>
                            </div>
                            <div :class="['powerbank-slot powerbank-slot__return', 'powerbank-slot__return__' + modalData.stock.available_slots]">
                                <div class="powerbank-icon mr-2" v-html="getSvg('powerbank-in')"></div>
                                <div class="powerbank-items">
                                    <span>{{ $t('map.take', 'Available to return:') }}</span>&nbsp;
                                    <span><strong>{{ modalData.stock.available_slots }}</strong>&nbsp;{{ $t('map.pieces', 'power banks') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="modalData.images.length">
                        <div class="images">
                            <img v-for="(image, imgIndex) of modalData.images" :src="image" />
                        </div>
                    </template>
                    <div class="buttons-wrap">
                        <a :href="createGMapsRouteUrl(modalData.address)" class="route-cta" target="_blank">
                            <span v-html="getSvg('route')"></span>
                            <span>{{ $t('map.directions', 'Route') }}</span>
                        </a>

                        <router-link class="rent-cta" :to="{ name: 'cabinet', params: { id: modalData.cabinet_id } }">
                            <span v-html="getSvg('powerbank-out')"></span>
                            <span>{{ $t('map.rent_powerbank', 'Rent') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { SVG } from "@/utils/svg";
import { APP } from "@/utils/constants";

export default {
    name: 'Map',
    data() {
        return {
            modalIsVisible: false,
            coordinates: '',
            map: {
                center: {
                    lat: 52.372465,
                    lng: 4.900040,
                },
                zoom: 13,
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                infoOptions: {
                    content: '',
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                icon:  {
                    url: require('../../assets/icons/marker_50x50.png'),
                    size: {
                        width: 50,
                        height: 50,
                    },
                },
            },
            markers: [],
            modalData: {
                title: 'Palas Mall',
                address: 'str Palat, nr 10, Palas Mall, etaj 1, langa magazin iStyle',
                cabinet_id: 'SHUA062012000101',
                price: {
                    hour: `${APP.HOURLY_PRICE} ${APP.CURRENCY_SYMBOL} / hour`,
                    day: 5,
                    currency: 'RON'
                },
                stock: {
                    available_powerbanks: 4,
                    available_slots: 2
                },
                comments: '',
                images: [
                    'https://images.unsplash.com/photo-1605191738062-c861b8afebc1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80',
                    'https://images.unsplash.com/photo-1605191737149-caf33c6efdd1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
                    'https://images.unsplash.com/photo-1605191738062-c861b8afebc1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80'
                ],
                program: {
                    1: {
                        open: '10:00',
                        close: '22:00'
                    },
                    2: {
                        open: '10:00',
                        close: '22:00'
                    },
                    3: {
                        open: '10:00',
                        close: '22:00'
                    },
                    4: {
                        open: '10:00',
                        close: '22:00'
                    },
                    5: {
                        open: '10:00',
                        close: '22:00'
                    },
                    6: {
                        open: '10:00',
                        close: '22:00'
                    },
                    7: {
                        open: '10:00',
                        close: '22:00'
                    },
                },
                todayProgram: {}
            }
        }
    },
    beforeMount() {
        const isNativePlatform = Capacitor.isNativePlatform();
        if (isNativePlatform) {
            this.getCurrentPosition();
        }
    },
    async mounted() {
        await this.$gmapApiPromiseLazy();
        // this.map.icon.size = new google.maps.Size(20, 32);
        this.markers = [
            {
                position: {
                    lat: 52.375237,
                    lng: 4.883891
                },
                data: {
                    title: 'Anne Frank House',
                    address: 'Westermarkt 20, 1016 GV Amsterdam, Netherlands',
                    address_comments: 'Located on the other side of the street',
                    cabinet_id: 'SHUA062012000101',
                    price: {
                        hour: APP.HOURLY_PRICE,
                        day: APP.MAX_PRICE_IN_HOURS,
                        currency: APP.CURRENCY_SYMBOL
                    },
                    stock: {
                        available_powerbanks: 4,
                        available_slots: 2
                    },
                    comments: '',
                    images: [
                        'https://gocharge.tech/wp-content/uploads/2022/07/03.png',
                        'https://gocharge.tech/wp-content/uploads/2022/07/goCharge_power_banks_0.5x-e1659543028196.png',
                        'https://gocharge.tech/wp-content/uploads/2022/06/stations.jpg',
                    ],
                    program: {
                        1: {
                            open: '10:00',
                            close: '22:00'
                        },
                        2: {
                            open: '10:00',
                            close: '22:00'
                        },
                        3: {
                            open: '10:00',
                            close: '22:00'
                        },
                        4: {
                            open: '10:00',
                            close: '22:00'
                        },
                        5: {
                            open: '10:00',
                            close: '22:00'
                        },
                        6: {
                            open: '10:00',
                            close: '22:00'
                        },
                        7: {
                            open: '10:00',
                            close: '22:00'
                        },
                    },
                }
            },
            {
                position: {
                    lat: 52.378251,
                    lng: 4.900029
                },
                data: {
                    title: 'Amsterdam Centraal',
                    address: 'Stationsplein, 1012 AB Amsterdam, Netherlands',
                    address_comments: 'inside railway station',
                    cabinet_id: 'SHUA062012000101',
                    price: {
                        hour: APP.HOURLY_PRICE,
                        day: APP.MAX_PRICE_IN_HOURS,
                        currency: APP.CURRENCY_SYMBOL
                    },
                    stock: {
                        available_powerbanks: 4,
                        available_slots: 2
                    },
                    comments: '',
                    images: [
                        'https://gocharge.tech/wp-content/uploads/2022/07/03.png',
                        'https://gocharge.tech/wp-content/uploads/2022/07/goCharge_power_banks_0.5x-e1659543028196.png',
                        'https://gocharge.tech/wp-content/uploads/2022/06/stations.jpg',
                    ],
                    program: {
                        1: {
                            open: '10:00',
                            close: '22:00'
                        },
                        2: {
                            open: '10:00',
                            close: '22:00'
                        },
                        3: {
                            open: '10:00',
                            close: '22:00'
                        },
                        4: {
                            open: '10:00',
                            close: '22:00'
                        },
                        5: {
                            open: '10:00',
                            close: '22:00'
                        },
                        6: {
                            open: '10:00',
                            close: '22:00'
                        },
                        7: {
                            open: '10:00',
                            close: '22:00'
                        },
                    },
                }
            },
            {
                position: {
                    lat: 52.373987,
                    lng: 4.898309
                },
                data: {
                    title: 'The Oude Church',
                    address: 'Oudekerksplein 23, 1012 GX Amsterdam, Netherlands',
                    address_comments: 'On the right side of the church',
                    cabinet_id: 'SHUA062012000101',
                    price: {
                        hour: APP.HOURLY_PRICE,
                        day: APP.MAX_PRICE_IN_HOURS,
                        currency: APP.CURRENCY_SYMBOL
                    },
                    stock: {
                        available_powerbanks: 4,
                        available_slots: 2
                    },
                    comments: '',
                    images: [
                        'https://gocharge.tech/wp-content/uploads/2022/07/03.png',
                        'https://gocharge.tech/wp-content/uploads/2022/07/goCharge_power_banks_0.5x-e1659543028196.png',
                        'https://gocharge.tech/wp-content/uploads/2022/06/stations.jpg',
                    ],
                    program: {
                        1: {
                            open: '10:00',
                            close: '22:00'
                        },
                        2: {
                            open: '10:00',
                            close: '22:00'
                        },
                        3: {
                            open: '10:00',
                            close: '22:00'
                        },
                        4: {
                            open: '10:00',
                            close: '22:00'
                        },
                        5: {
                            open: '10:00',
                            close: '22:00'
                        },
                        6: {
                            open: '10:00',
                            close: '22:00'
                        },
                        7: {
                            open: '10:00',
                            close: '22:00'
                        },
                    },
                }
            },
            {
                position: {
                    lat: 52.371407,
                    lng: 4.915191
                },
                data: {
                    title: 'The National Maritime Museum',
                    address: 'Kattenburgerplein 1, 1018 KK Amsterdam, Netherlands',
                    address_comments: 'On the right side of the building',
                    cabinet_id: 'SHUA062012000101',
                    price: {
                        hour: APP.HOURLY_PRICE,
                        day: APP.MAX_PRICE_IN_HOURS,
                        currency: APP.CURRENCY_SYMBOL
                    },
                    stock: {
                        available_powerbanks: 4,
                        available_slots: 2
                    },
                    comments: '',
                    images: [
                        'https://gocharge.tech/wp-content/uploads/2022/07/03.png',
                        'https://gocharge.tech/wp-content/uploads/2022/07/goCharge_power_banks_0.5x-e1659543028196.png',
                        'https://gocharge.tech/wp-content/uploads/2022/06/stations.jpg',
                    ],
                    program: {
                        1: {
                            open: '10:00',
                            close: '22:00'
                        },
                        2: {
                            open: '10:00',
                            close: '22:00'
                        },
                        3: {
                            open: '10:00',
                            close: '22:00'
                        },
                        4: {
                            open: '10:00',
                            close: '22:00'
                        },
                        5: {
                            open: '10:00',
                            close: '22:00'
                        },
                        6: {
                            open: '10:00',
                            close: '22:00'
                        },
                        7: {
                            open: '10:00',
                            close: '22:00'
                        },
                    },
                }
            },
            {
                position: {
                    lat: 52.366167,
                    lng: 4.912583
                },
                data: {
                    title: 'ARTIS',
                    address: 'Plantage Kerklaan 38-40, 1018 CZ Amsterdam, Netherlands',
                    address_comments: '9W88+CJ Amsterdam, Netherlands',
                    cabinet_id: 'SHUA062012000101',
                    price: {
                        hour: APP.HOURLY_PRICE,
                        day: APP.MAX_PRICE_IN_HOURS,
                        currency: APP.CURRENCY_SYMBOL
                    },
                    stock: {
                        available_powerbanks: 4,
                        available_slots: 2
                    },
                    comments: '',
                    images: [
                        'https://gocharge.tech/wp-content/uploads/2022/07/03.png',
                        'https://gocharge.tech/wp-content/uploads/2022/07/goCharge_power_banks_0.5x-e1659543028196.png',
                        'https://gocharge.tech/wp-content/uploads/2022/06/stations.jpg',
                    ],
                    program: {
                        1: {
                            open: '10:00',
                            close: '22:00'
                        },
                        2: {
                            open: '10:00',
                            close: '22:00'
                        },
                        3: {
                            open: '10:00',
                            close: '22:00'
                        },
                        4: {
                            open: '10:00',
                            close: '22:00'
                        },
                        5: {
                            open: '10:00',
                            close: '22:00'
                        },
                        6: {
                            open: '10:00',
                            close: '22:00'
                        },
                        7: {
                            open: '10:00',
                            close: '22:00'
                        },
                    },
                }
            },
        ];
    },
    computed: {
        getGmapOptions() {
            return {
                disableDefaultUI: true,
                styles: [{
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "saturation": 36
                    }, {
                        "color": "#1B232A"
                    }, {
                        "lightness": 40
                    }]
                }, {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "visibility": "on"
                    }, {
                        "color": "#1B232A"
                    }, {
                        "lightness": 16
                    }]
                }, {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 20
                    }]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 17
                    }, {
                        "weight": 1.2
                    }]
                }, {
                    "featureType": "administrative",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "administrative.country",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                }, {
                    "featureType": "administrative.country",
                    "elementType": "geometry",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                }, {
                    "featureType": "administrative.country",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                }, {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "administrative.locality",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "simplified"
                    }, {
                        "saturation": "-100"
                    }, {
                        "lightness": "30"
                    }]
                }, {
                    "featureType": "administrative.neighborhood",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "administrative.land_parcel",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "simplified"
                    }, {
                        "gamma": "0.00"
                    }, {
                        "lightness": "74"
                    }]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 20
                    }]
                }, {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [{
                        "lightness": "3"
                    }]
                }, {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 21
                    }]
                }, {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 17
                    }]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 29
                    }, {
                        "weight": 0.2
                    }]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 18
                    }]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 16
                    }]
                }, {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 19
                    }]
                }, {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#1B232A"
                    }, {
                        "lightness": 17
                    }]
                }]
            }
        }
    },
    methods: {
        async getCurrentPosition() {
            Geolocation.getCurrentPosition().then((coordinates) => {
                this.coordinates = coordinates.coords;
                this.map.center = {
                    lat: parseFloat(this.coordinates.latitude.toFixed(6)),
                    lng: parseFloat(this.coordinates.longitude.toFixed(6)),
                }
            });

        },
        closeLocationModal() {
            this.modalIsVisible = false;
        },
        toggleLocationModal(modal, index) {
            this.modalData = modal.data;
            this.modalIsVisible = this.modalIsVisible === index ? false : index;
        },
        toggleInfoWindow(marker, idx) {
            this.map.infoWindowPos = marker.position;
            this.map.infoOptions.content = marker.infoText;

            //check if its the same marker that was selected if yes toggle
            if ( this.map.currentMidx == idx ) {
                this.map.infoWinOpen = !this.map.infoWinOpen;
            } else {
                this.map.infoWinOpen = true;
                this.map.currentMidx = idx;
            }
        },
        getSvg(svg) {
            return SVG[svg];
        },
        getPrice(price, currency) {
            if ( currency.includes('RON') ) {
                return price + ' ' + currency;
            }

            return currency + price;
        },
        isModalLocationOpenNow(modalData) {
            let currentDate = new Date();
            let currentDayIndex = currentDate.getDay();
            if (currentDayIndex === 0) {
                currentDayIndex = 7;
            }
            this.modalData.todayProgram = modalData.program[currentDayIndex];
            let open = modalData.program[currentDayIndex].open.replace(':', '');
            let close = modalData.program[currentDayIndex].close.replace(':', '');
            let hour = currentDate.getHours();
            if (hour < 10) {
                hour = [ hour, '0' ].join('');
            }
            let minutes = currentDate.getMinutes();
            if (minutes < 10) {
                minutes = [ minutes, '0' ].join('');
            }
            let currentTime = [hour, minutes].join('');
            if (currentTime > open && currentTime < close) {
                return true;
            }

            return false;
        },
        createGMapsRouteUrl(address) {
            let params = {
                'api': '1',
                'travelmode': 'walking',
                'destination': address,
            }
            let url = new URL('https://www.google.com/maps/dir/');
            for (const key in params) {
                url.searchParams.append(key, params[key]);
            }

            return url;
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/map.scss';
</style>