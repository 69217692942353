<template>
    <div class="homepage app-page flex flex-col">
        <div class="app-page__wrap login__wrap w-full flex items-start justify-center px-4 sm:px-6 lg:px-8">
            <message-snippet
                type="onboarding"
                title=""
                subtitle="goCharge.tech"
                :loading-text="getLoadingText"
                bold-text=""
                :router-link="{name: 'map'}"
                :router-link-props="{
                    'text': 'Find closest station',
                    'class':
                    'relative flex justify-center py-2 px-4 mt-6 border border-transparent text-sm font-light font-medium rounded-md color-1 bg-color-4 focus:outline-none focus:ring-2 focus:ring-offset-2'
                }"
            ></message-snippet>
        </div>
    </div>
</template>

<script>
import MessageSnippet from "@/components/common/message-snippet";

export default {
    name: 'Homepage',
    components: {
        MessageSnippet
    },
    computed: {
        getLoadingText() {
            return `
                Rent power banks and charge devices on the go. Use it as long as you need it and then return it to any available station (at any goCharge station in any EU country).
            `;
        }
    },
    methods: {}
};
</script>
