<template>
  <div id="app" :class="['platform-' + platform, isScanning]">
      <div class="page-title-wrap">
          <h2>{{ $route.meta.title ? $route.meta.title : 'goCharge' }}</h2>
      </div>
      <div class="beta-label">Beta</div>
      <div class="constellation">
          <svg width="414" height="488" viewBox="0 0 414 488" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.5">
                  <circle cx="8.47446" cy="338.243" r="1.20298" transform="rotate(-90 8.47446 338.243)" fill="#777777"/>
                  <circle cx="28.5252" cy="331.426" r="1.20298" transform="rotate(-90 28.5252 331.426)" fill="#777777"/>
                  <circle cx="28.9256" cy="351.476" r="1.20298" transform="rotate(-90 28.9256 351.476)" fill="#777777"/>
                  <path d="M29.1267 331.627L8.67607 338.043M29.1267 331.627L-15.3835 322.204M29.1267 331.627L29.1267 351.075L8.67607 338.043M8.67607 338.043L-20.7969 381.751L-15.3835 322.204M8.67607 338.043L-15.3835 322.204" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <g opacity="0.5">
                  <path d="M343 108L374.5 139.5L395 110.5V56L343 108Z" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="396" cy="55" r="3" fill="#777777"/>
                  <circle cx="374" cy="140" r="3" fill="#777777"/>
                  <circle cx="396" cy="110" r="3" fill="#777777"/>
                  <circle cx="343" cy="108" r="3" fill="#777777"/>
              </g>
              <g opacity="0.5">
                  <path d="M399 378L446 405L388.689 424.98L399 486L352 459V405L399 378Z" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="399" cy="379" r="3" fill="#777777"/>
                  <circle cx="390" cy="425" r="3" fill="#777777"/>
                  <circle cx="399" cy="485" r="3" fill="#777777"/>
                  <circle cx="352" cy="459" r="3" fill="#777777"/>
                  <circle cx="352" cy="405" r="3" fill="#777777"/>
              </g>
              <g opacity="0.5">
                  <circle cx="42.6629" cy="11.441" r="3" transform="rotate(40.9501 42.6629 11.441)" fill="#777777"/>
                  <circle cx="39.4735" cy="36.4781" r="3" transform="rotate(40.9501 39.4735 36.4781)" fill="#777777"/>
                  <circle cx="11.4149" cy="30.6666" r="3" transform="rotate(40.9501 11.4149 30.6666)" fill="#777777"/>
                  <circle cx="-3.17107" cy="74.941" r="3" transform="rotate(40.9501 -3.17107 74.941)" fill="#777777"/>
                  <path d="M12.1703 31.3225L42.6622 11.4417L39.8503 36.8059M12.1703 31.3225L39.8503 36.8059M12.1703 31.3225L-3.77764 76.402L39.8503 36.8059" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
          </svg>
      </div>
      <router-view></router-view>
      <navigation></navigation>
  </div>
</template>

<script>
import {
    USER_GET_LOGGED_IN,
    USER_LOGOUT,
    USER_SET_PROFILE_DATA
} from "@/store/modules/user";
import Navigation from "./components/navigation/navigation";
import { QueryMyProfileData } from "@/graphql/profile";
import { Capacitor } from "@capacitor/core";

export default {
    name: 'App',
    components: {
      Navigation
    },
    data() {
        return {
            platform: 'web',
            isScanning: ''
        }
    },
    beforeMount() {
        this.platform = Capacitor.getPlatform();
        this.$root.$on('isScanning', (event) => {
            this.isScanning = event ? 'is-scanning' : '';
        })
    },
    created() {
        // import('@/assets/scss/platform-' + this.platform + '.scss');
    },
    mounted() {
        let _vm = this;
        this.$root.$on('userLoginStatusChanged', function () {
            _vm.getUserProfileData();
        })

        this.getUserProfileData();
        this.watchErdLogin();
    },
    computed: {
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        isUserLoggedInWithElrond() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
    },
    methods: {
        getUserProfileData() {
            if (!this.isUserLoggedInWithEmail) {
                return;
            }
            this.$apollo.query({
                query: QueryMyProfileData,
                fetchPolicy: 'network-only'
            }).then((data) => {
                if (!data.data.profile) {
                    this.logout();
                }
                this.$store.commit('user/' + USER_SET_PROFILE_DATA, data.data.profile);
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
                this.logout();
            })
        },
        logout() {
            this.$store.dispatch( 'user/' + USER_LOGOUT );
            this.$router.push({ name: 'login' })
        },
        watchErdLogin() {
            this.$watch('$erd.logged', (newVal, oldVal) => {
                if( newVal === true && oldVal === false ) {
                    console.log(999, 'TODO: implement this method');
                }
            })
        },
        generateRandomString() {
            const chars = ['ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', '0123456789', 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'];

            return [15, 5, 12].map(function(len, i) { return Array(len).fill(chars[i]).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('') }).concat().join('').split('').sort(function(){return 0.5-Math.random()}).join('');
        }
    }
}
</script>