<template>
    <div class="admin">
        Admin
    </div>
</template>

<script>
    export default {
        name: 'Admin'
    };
</script>
