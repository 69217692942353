import { QrCode } from "./qr-code";

class QrCodeHandler implements QrCode {
    async handle(data: string, element: HTMLElement): Promise<any> {
        const QRCode = require('qrcode');
        return await QRCode.toString(data, {
            type: "svg",
        }).then((svg: any) => {
            return svg;
        });
    }
}

export default QrCodeHandler;
