import gql from 'graphql-tag';

export const QueryMyProfileData = gql`query myProfileData{
  profile {
    id
    email_address
    is_admin
    locale
    user_id
    full_name
  }
}`;