<template>
    <div class="dapp-logged-in-wrapper">
        <div v-if="isUserLoggedIn" class="dapp-login__card text-center p-8 shadow-lg rounded-lg">
            <h2 class="mb-6 text-center text-2xl font-bold text-gray-900 pb-4 border-gray-100">Account details</h2>
            <div class="flex flex-row flex-wrap content-center justify-center">
                <div class="dapp-login__card-address">Wallet address:&nbsp;</div>
                <strong class="mb-6 block">{{$erd.obfuscatedWalletAddress}}</strong>
            </div>
            <div class="flex flex-row justify-center content-center space-x-3">
                <a :href="getExplorerAddress"
                   ref="noreferrer"
                   target="_blank"
                   :class="[
                        'dapp-login__card-logout',
                        'relative flex justify-center py-2 px-4 text-sm font-medium',
                        'rounded-md border-color-4 border color-4'
                    ]">View on Explorer</a>
                <button :class="[
                        'dapp-login__card-logout',
                        'relative flex justify-center py-2 px-4 text-sm font-medium',
                        'rounded-md border-color-4 border color-4'
                    ]"
                    @click.prevent="logout()">Disconnect</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DappLoggedInCard',
    mounted() {
        this.redirect();
    },
    methods: {
        redirect() {
            if (this.$router && this.$erd.walletAddress && this.$route.query.fromUrl) {
                this.$router.push(this.$route.query.fromUrl);
            }
        },
        logout() {
            this.selectedMode = null;
            this.$erd.logout();
        }
    },
    watch: {
        "$erd.walletAddress": function(address) {
            if(address != null){
                this.redirect();
            }
        }
    },
    computed: {
        isUserLoggedIn() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
        getExplorerAddress() {
            return `https://explorer.elrond.com/accounts/${this.$erd.walletAddress}`;
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/erdjs.scss';
</style>
