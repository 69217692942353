<template>
    <div
        class="dapp-login__tab"
        v-if="openContent">
        <div class="dapp-login__tab-qr-wrap">
            <div class="dapp-login__tab-qr" ref="qrcode"></div>
            <div class="dapp-login__tab-qr mb-5" v-if="qrcode" v-html="qrcode"></div>
            <a
                class="dapp-login__tab-link dapp-login__tab-link-maiar color-1 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-color-2 focus:outline-none mb-3"
                :href="deeplink"
                v-if="deeplink && isMobile()">
                <span class="mt-1 mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.114 44.981" width="16" height="16" class="block"
                                  style="width: 0.9rem; height: 0.9rem;"><g
                    fill-rule="evenodd" fill="#1B232A"><path d="m20.547 1.001-3.832 17.8H7.054Z"></path><path d="m31.41 17.802-7.053 9.379H0l7.054-9.379Z"></path><path d="m24.357 26.18-13.493 17.8 3.832-17.8Z"></path></g></svg></span>
                <span class="color-1">Maiar Login</span>
            </a>
        </div>
    </div>
</template>

<script>
import platform from "platform";
import QrCodeHandler from '@/dapp/maiar/qr-code-handler';

export default {
    name: 'ErdjsLoginMaiar',
    data () {
        return {
            openContent: false,
            qrcode: null,
            deeplink: null
        }
    },
    props: {
        selectedMode: {
            type: String,
            default: ''
        },
        qrcodeHandler: {
            require: true,
            default: function() { return new QrCodeHandler() }
        },
        token: {
            require: false,
            type: String
        }
    },
    watch: {
        selectedMode (selectedMode) {
            if ( selectedMode === 'Maiar App' ) {
                this.login();
            } else {
                this.openContent = false;
            }
        }
    },
    methods: {
        isMobile() {
            return platform.os.family === "iOS" || platform.os.family === "Android";
        },
        login() {
            console.log("Maiar App Login")
            this.openContent = true;
            this.qrcode = null;
            this.deepLink = null;
            const that = this;
            const options = this.token ? { token: this.token } : {}
            this.$erd.maiarApp.login(options).then((loginData) => {
                console.log(loginData)
                this.qrcodeHandler.handle(loginData.qrCodeData, this.$refs.qrcode).then((svg) => {
                    if(svg) {
                        this.qrcode = svg;
                    }
                })
                that.deeplink = loginData.deeplink;
                that.$emit('login', loginData);
            });
        }
    }
}
</script>
