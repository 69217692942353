import Vue from 'vue';
import i18next from 'i18next';
import en from './lang/en.json';
import ro from './lang/ro.json';
import VueI18Next from '@panter/vue-i18next';

Vue.use(VueI18Next);

i18next.init({
    fallbackLng: 'en',
    debug: false,
    resources: { en, ro }
    }, ( err ) => {
    if (err) {
        /*eslint no-console: ["error", { allow: ["warn"] }] */
        console.warn('something went wrong loading', err);
    }
});

i18next.on('languageChanged', function(lng) {
    window.powerbank.$root.$emit('languageChanged', lng)
})

const i18n = new VueI18Next(i18next);

export default i18n;