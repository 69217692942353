<template>
    <div class="not-found">
        Not Found
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    };
</script>
