import { apolloClient } from '@/graphql/apollo-client';
import { MutationRefreshAuthToken } from "@/graphql/auth";

export const USER_SET_AUTH_TOKEN = 'userSetAuthToken';
export const USER_GET_AUTH_TOKEN = 'userGetAuthToken';
export const USER_SET_REFRESH_TOKEN = 'userSetRefreshToken';
export const USER_GET_REFRESH_TOKEN = 'userGetRefreshToken';
export const USER_SET_LOGGED_IN = 'userSetLoggedIn';
export const USER_GET_LOGGED_IN = 'userGetLoggedIn';
export const USER_REFRESH_AUTH_TOKEN = 'userRefreshAuthToken';
export const USER_LOGOUT = 'userLogout';
export const USER_RESET_LOGIN_STATE = 'userResetLoginState';
export const USER_SET_PROFILE_DATA = 'userSetProfileData';
export const USER_GET_PROFILE_DATA = 'userGetProfileData';


const emptyState = {
    email: '',
    name: '',
    id: '',
    authToken: '',
    refreshToken: '',
    isLoggedIn: false,
    isAdmin: false
};

const state = { ...emptyState };

const mutations = {
    [USER_SET_AUTH_TOKEN](state, payload) {
        state.authToken = payload;
    },
    [USER_SET_LOGGED_IN](state, payload) {
        state.isLoggedIn = payload;
    },
    [USER_SET_REFRESH_TOKEN](state, payload) {
        state.refreshToken = payload;
    },
    [USER_RESET_LOGIN_STATE](state){
        for (const [key, value] of Object.entries(emptyState)) {
            if (key) {
                state[key] = emptyState[key];
            }
        }
    },
    [USER_SET_PROFILE_DATA](state, payload) {
        state.email = payload.email_address;
        state.name = payload.full_name;
        state.id = payload.id;
        state.isAdmin = payload.is_admin;
    }
};

const actions = {
    [USER_LOGOUT]({commit}) {
        commit(USER_RESET_LOGIN_STATE);
    },
    [USER_REFRESH_AUTH_TOKEN]({commit, state, dispatch}) {
        return state.refreshToken
            ? new Promise((resolve, reject) => {
                apolloClient.mutate({
                    mutation: MutationRefreshAuthToken,
                    variables: {
                        input: {
                            'clientMutationId': USER_REFRESH_AUTH_TOKEN + 'Id',
                            'jwtRefreshToken': state.refreshToken,
                        }
                    }
                }).then( (results) => {
                    console.log('success')
                    commit(USER_SET_AUTH_TOKEN, results.data.refreshJwtAuthToken.authToken);
                    commit(USER_SET_LOGGED_IN, true);
                    resolve(results.data);
                }).catch((error) => {
                    console.log('error', state.refreshToken)
                    dispatch(USER_LOGOUT);
                });
            })
            : '';
    },
};

const getters = {
    [USER_GET_AUTH_TOKEN](state) {
        return state.authToken;
    },
    [USER_GET_LOGGED_IN](state) {
        return state.isLoggedIn;
    },
    [USER_GET_REFRESH_TOKEN](state) {
        return state.refreshToken;
    },
    [USER_GET_PROFILE_DATA](state) {
        let data = {...state};
        delete data.authToken;
        delete data.refreshToken;

        return data;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};