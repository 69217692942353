<template>
    <div class="cabinet-wrap max-w-md mx-auto">
        <template v-if="isLoading">
            <loading-snippet :loading-text="$t('cabinet.loading', 'Loading details...')"></loading-snippet>
        </template>
        <template v-else>
            <div class="cabinet-data">
                <div :class="['cabinet-status', 'cabinet-status__online' ]">
                    <span class="mr-3" v-html="getSvg( 'flash' )"></span>
                    <span>{{ $t('cabinet.current_status', 'Station status:') }}&nbsp;online</span>
<!--                    <span>{{ getCabinetStatus }}</span>-->
                </div>

<!--                <div :class="['cabinet-status', 'cabinet-status__' + getCabinetStatus ]">-->
<!--                    <span class="mr-2" v-html="getSvg( getCabinetStatus === 'offline' ? 'flash-slash' : 'flash' )"></span>-->
<!--                    <span>{{ $t('cabinet.current_status', 'Cabinet is') }}&nbsp;</span>-->
<!--                    <span>{{ getCabinetStatus }}</span>-->
<!--                </div>-->

                <div class="cabinet-address">
                    <Icon name="location" class-name="location-icon"/><span>6475 Sanford Plaza, Amsterdam, Netherlands</span>
<!--                    <Icon name="location" class-name="location-icon"/><span>{{ cabinet.street_address }}</span>-->
                </div>

                <div class="flex flex-col powerbank-slot-wrap">
                    <div :class="['powerbank-slot powerbank-slot__borrow', 'powerbank-slot__borrow__' + cabinet.available_powerbanks]">
                        <div class="powerbank-icon mr-2" v-html="getSvg('powerbank-out')"></div>
                        <div class="powerbank-items">{{ cabinet.available_powerbanks }} {{ $t('cabinet.available_powerbanks', 'available powerbanks') }}</div>
                    </div>
                    <div :class="['powerbank-slot powerbank-slot__return', 'powerbank-slot__return__' + cabinet.available_slots]">
                        <div class="powerbank-icon mr-2" v-html="getSvg('powerbank-in')"></div>
                        <div class="powerbank-items">{{ cabinet.available_slots }} {{ $t('cabinet.available_slots', 'powerbanks can be returned') }}</div>
                    </div>
                </div>

                <div class="cabinet-price">
                    <Icon name="card" class-name="card-icon"/>
                    <div class="price-label">{{ $t('cabinet.price', 'Price') }}&nbsp;-&nbsp;</div>
                    <div class="price-value">{{ getHourlyPrice() }}, {{ getDailyPrice() }}</div>
                </div>
                <div v-if="isUserLoggedInWithElrond" class="cabinet-cta">
                    <router-link :to="{ name: 'rent-dapp' }" class="borrow-cta">
                        <span class="mr-3" v-html="getSvg('bitcoin')"></span>
                        <span>{{ $t('cabinet.borrow_cta', 'Pay with crypto') }}</span>
                    </router-link>
                </div>
                <div v-if="!isUserLoggedInWithElrond"  class="cabinet-cta">
                    <router-link :to="{ name: 'rent' }" class="borrow-cta borrow-cta__secondary">
                        <span class="mr-3" v-html="getSvg('credit-card')"></span>
                        <span>{{ $t('cabinet.borrow_cta', 'Pay with credit card') }}</span>
                    </router-link>
                </div>

<!--                <div v-if="( getCabinetStatus === 'online' && cabinet.available_powerbanks > 0 )" class="cabinet-cta">-->
<!--                    <router-link :to="{ name: getRentPageName }" class="borrow-cta borrow-cta__secondary">-->
<!--                        <span class="mr-3" v-html="getSvg('credit-card')"></span>-->
<!--                        <span>{{ $t('cabinet.borrow_cta', 'Pay with credit card') }}</span>-->
<!--                    </router-link>-->
<!--                </div>-->

            </div>
        </template>
    </div>
</template>

<script>
import CabinetsList from "./list/list";
import Icon from "../common/icon";
import { USER_GET_PROFILE_DATA, USER_GET_LOGGED_IN } from "../../store/modules/user";
import { GetCabinetById } from "@/graphql/cabinets";
import { SVG } from "@/utils/svg";
import LoadingSnippet from "@/components/common/loading-snippet/loading-snippet";
import { APP } from "@/utils/constants";

export default {
    name: 'Cabinet',
    components: {
        LoadingSnippet,
        CabinetsList,
        Icon,
    },
    data() {
        return {
            isLoading: true,
            cabinet: {},
        }
    },
    apollo: {
        cabinet: {
            query: GetCabinetById,
            fetchPolicy: 'network-only',
            variables: function() {
                return {
                    id: this.cabinetId,
                    idType: 'cabinet_id'
                };
            },
            result ( { loading } ) {
                if ( ! loading ) {
                    this.isLoading = false;
                }
            }
        }
    },
    methods: {
        getSvg(svg) {
            return SVG[svg];
        },
        borrowPowerbank() {
            alert('Renting in progress...')
        },
        getHourlyPrice() {
            return `${APP.HOURLY_PRICE} ${APP.CURRENCY_SYMBOL} / hour`
        },
        getDailyPrice() {
            return `max ${APP.HOURLY_PRICE * APP.MAX_PRICE_IN_HOURS} ${APP.CURRENCY_SYMBOL} / day`
        }
    },
    computed: {
        cabinetId() {
            return this.$route.params.id;
        },
        isUserLoggedIn() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        isUserLoggedInWithElrond() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
        userProfileData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        isAdmin() {
            return this.userProfileData.isAdmin;
        },
        getCabinetStatus() {
            return this.cabinet.is_online == 1 ? 'online' : 'offline';
        },
        loaderUrl() {
            return require('../../assets/icons/loader.gif');
        },
        getRentPageName() {
            return this.isUserLoggedInWithElrond
                ? 'rent-dapp'
                : 'rent';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/cabinet.scss';
</style>