<template>
    <div
        class="dapp-login__tab"
        v-if="openContent">
        <div class="dapp-login__tab-info">
            <p class="dapp-login__tab-text">
                Please unlock Maiar DeFi Extension and select the wallet you want to connect.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErdjsLoginWebWallet',
    data () {
        return {
            error: null,
            openContent: false
        }
    },
    props: {
        selectedMode: {
            type: String,
            default: ''
        },
        token: {
            require: false,
            type: String
        }
    },
    watch: {
        selectedMode (selectedMode) {
            console.log("Elrond Web Wallet", selectedMode)
            if ( selectedMode === 'Elrond Web Wallet' ) {
                this.login(selectedMode)
                this.openContent = true;
            } else {
                this.openContent = false;
            }
        }
    },
    mounted() {
        this.$erd.webWallet.callbackReceived(window.location.search);
    },
    methods: {
        login (name) {
            this.$emit('select-mode', name);
            const options = this.token ? { token: this.token } : {};
            this.$erd.webWallet.login(options);
        }
    }
}
</script>
