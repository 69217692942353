<template>
    <div class="dapp-login-wrapper">
        <div
            class="dapp-login__card p-6"
            v-if="!isUserLoggedIn">
                <div v-if="!selectedMode" class="dapp-login__buttons">
                    <div class="grid gap-5 grid-cols-1 grid-rows-4">
                        <ErdjsLoginButton
                            name="Maiar DeFi Wallet"
                            @select-mode="selectedMode = $event"
                            :selected-mode="selectedMode"></ErdjsLoginButton>
                        <ErdjsLoginButton
                            name="Maiar App"
                            @select-mode="selectedMode = $event"
                            :selected-mode="selectedMode"></ErdjsLoginButton>
                        <ErdjsLoginButton
                            name="Ledger"
                            @select-mode="selectedMode = $event"
                            :selected-mode="selectedMode"></ErdjsLoginButton>
                        <ErdjsLoginButton
                            name="Elrond Web Wallet"
                            @select-mode="selectedMode = $event"
                            :selected-mode="selectedMode"></ErdjsLoginButton>
                    </div>
                </div>

                <div v-show="selectedMode" class="dapp-login__item">
                    <div class="grid gap-4 grid-cols-1 pb-6">
                        <div class="erdjs-item-text flex flex-row justify-between content-center pb-3 mb-5">
                            <label>{{ selectedMode }}</label>
                            <button @click.prevent="selectedMode = ''" class="erdjs-close-button rounded">
                                <svg width="32" height="32" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 15L29 29" stroke="#777777" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 29L29 15" stroke="#777777" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <ErdjsLoginDefi
                            :selected-mode="selectedMode"
                            :token="token"></ErdjsLoginDefi>
                        <ErdjsLoginMaiar
                            :selected-mode="selectedMode"
                            :qrcodeHandler="qrcode"
                            :token="token"></ErdjsLoginMaiar>
                        <ErdjsLoginLedger
                            :selected-mode="selectedMode"
                            :token="token"></ErdjsLoginLedger>
                        <ErdjsLoginWebWallet
                            @select-mode="selectedMode = $event"
                            :selected-mode="selectedMode"
                            :token="token"></ErdjsLoginWebWallet>
                    </div>
                </div>
        </div>
        <DappLoggedInCard></DappLoggedInCard>
    </div>
</template>

<script>
import ErdjsLoginButton from "@/dapp/parts/erdjs-login-button"
import QrCodeHandler from '@/dapp/maiar/qr-code-handler';
import ErdjsLoginDefi from '@/dapp/parts/erdjs-login-defi';
import ErdjsLoginMaiar from '@/dapp/parts/erdjs-login-maiar';
import ErdjsLoginLedger from '@/dapp/parts/erdjs-login-ledger';
import ErdjsLoginWebWallet from '@/dapp/parts/erdjs-login-web-wallet';
import DappLoggedInCard from '@/dapp/parts/logged-in-card';

export default {
    name: 'DappLoginTab',
    components: {
        ErdjsLoginButton,
        ErdjsLoginDefi,
        ErdjsLoginMaiar,
        ErdjsLoginLedger,
        ErdjsLoginWebWallet,
        DappLoggedInCard,
    },
    props: {
        qrcode: {
            require: true,
            default: function() { return new QrCodeHandler() }
        },
        token: {
            require: false,
            type: String
        }
    },
    data () {
        return {
            selectedMode: ''
        }
    },
    mounted() {
        this.redirect();
    },
    methods: {
        redirect() {
            if (this.$router && this.$erd.walletAddress && this.$route.query.fromUrl) {
                this.$router.push(this.$route.query.fromUrl);
            }
        },
        logout() {
            this.selectedMode = null;
            this.$erd.logout();
        }
    },
    watch: {
        "$erd.walletAddress": function(address) {
            if(address != null){
                this.redirect();
            }
        }
    },
    computed: {
        isUserLoggedIn() {
            return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
        },
        getExplorerAddress() {
            return `https://explorer.elrond.com/accounts/${this.$erd.walletAddress}`;
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/erdjs.scss';
</style>
