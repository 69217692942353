<template>
    <div class="login__card">
        <template v-if="!isUserLoggedIn">
            <div class="tabs__header py-5 my-5">
                <h2 class="text-2xl font-bold pb-3">Sign in with email address</h2>
                <p class="font-light">Please use your email address and password to login.</p>
            </div>
            <div class="tabs__content max-w-md w-full space-y-5">
                <form class="form mt-8 space-y-6" @submit.prevent="loginUser()">
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div class="form__row">
                            <label for="email-address" class="form__label">Email address</label>
                            <input id="email-address" class="form__input relative block w-full px-3 py-2 sm:text-sm"
                                   v-model="user_data.email"
                                   name="email"
                                   type="email"
                                   autocomplete="email"
                                   placeholder="Enter email address..." />
                        </div>
                        <div class="form__row">
                            <label for="password" class="form__label">Password</label>
                            <input id="password" class="form__input relative block w-full px-3 py-2 sm:text-sm"
                                   v-model="user_data.password"
                                   name="password"
                                   type="password"
                                   autocomplete="current-password"
                                   required=""
                                   placeholder="Enter password..." />
                        </div>
                    </div>

                    <div class="flex items-center justify-between text-sm">
                        <a href="#" @click.prevent="$emit('action', 'register')" class="font-light link">
                            Sign Up
                        </a>
                        <a href="#" class="font-light link">
                            Forgot your password?
                        </a>
                    </div>

                    <div>
                        <button @click.prevent="loginUser" type="submit"
                                :disabled="isLoading"
                                class="relative w-full flex justify-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-2">
                            <div v-if="isLoading" class="spinner-border animate-spin inline-block w-4 h-4 border-4 border-color-1 rounded-full color-1"
                                 role="status"></div>
                            <span>Sign in</span>
                        </button>
                    </div>
                </form>
            </div>
        </template>
        <template v-if="isUserLoggedIn">
            <h3 class="mb-6 text-center text-xl font-bold text-gray-900">{{ getGreetingText }}</h3>
            <div v-if="userData.email" class="text-center mb-6">
                <span class="mr-2">You're logged in with email address: </span>
                <strong>{{ userData.email }}</strong>
            </div>
            <div class="flex flex-row justify-center">
                <button
                    @click.prevent="logout()"
                    class="relative py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-2"
                >Logout</button>
            </div>
        </template>
    </div>
</template>

<script>
import { MutationLoginUser } from '@/graphql/auth';
import {
    USER_SET_AUTH_TOKEN,
    USER_SET_LOGGED_IN,
    USER_SET_REFRESH_TOKEN
} from '@/store/modules/user';
import {USER_GET_LOGGED_IN, USER_GET_PROFILE_DATA, USER_LOGOUT} from "../../store/modules/user";
import DappLoginTab from '@/dapp/parts/login-tab'

export default {
    name: 'LoginCard',
    components: {
        DappLoginTab
    },
    data () {
        return {
            user_data: {
                email: '',
                password: '',
                remember: false
            },
            validator: {
                has_errors: false,
                message: ''
            },
            isLoading: false
        }
    },
    methods: {
        loginUser() {
            this.isLoading = true;
            this.validateFields();
            if (!this.validator.has_errors) {
                let input = {
                    clientMutationId: 'mutationLoginUserId',
                    username: this.user_data.email,
                    password: this.user_data.password,
                }

                this.$apollo.mutate({
                    mutation: MutationLoginUser,
                    variables: {
                        input: input,
                    },
                }).then((data) => {
                    if ( data.data.login.authToken ) {
                        this.$store.commit( 'user/' + USER_SET_AUTH_TOKEN, data.data.login.authToken );
                        this.$store.commit( 'user/' + USER_SET_REFRESH_TOKEN, data.data.login.refreshToken );
                        this.$store.commit( 'user/' + USER_SET_LOGGED_IN, true );
                        this.$router.push({ name: 'dashboard' });
                        this.$root.$emit('userLoginStatusChanged', true);
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    // Error
                    console.log('error')
                    alert('Error: ' + error.message.replace('GraphQL error:', ''))
                    this.isLoading = false;
                })
            } else {
                this.isLoading = false;
                alert(this.validator.message)
            }
        },
        logout() {
            this.$erd.logout();
            this.$store.dispatch( 'user/' + USER_LOGOUT );
        },
        validateFields() {
            this.$set(this.validator, 'has_errors', false);
            this.$set(this.validator, 'message', '');
            if (!this.user_data.email.trim()) {
                this.$set(this.validator, 'has_errors', true);
                this.$set(this.validator, 'message', 'Email address cannot be empty.');

                return false;
            }
            if (!this.user_data.password.trim()) {
                this.$set(this.validator, 'has_errors', true);
                this.$set(this.validator, 'message', 'Password field cannot be empty.');
            }
        }
    },
    computed: {
        isUserLoggedIn() {
            let loggedIn = this.isUserLoggedInWithEmail;
            if( !loggedIn ) {
                return this.$erd.logged && this.$erd.walletAddress && !this.$erd.walletAddress.isEmpty();
            }

            return loggedIn;
        },
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        userData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        getGreetingText() {
            if( this.userData.name ) {
                return `Howdy, ${this.userData.name}!`;
            }

            return 'Howdy!';
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/scss/login.scss';
</style>
