<template>
    <div class="dashboard">
        <DappLoggedInCard></DappLoggedInCard>
        <template v-if="isUserLoggedInWithEmail">
            <h3 class="my-6 text-center text-xl font-bold text-gray-900">{{ getGreetingText }}</h3>
            <div v-if="userData.email" class="text-center mb-6">
                <span class="mr-2">You're logged in with email address: </span>
                <strong>{{ userData.email }}</strong>
            </div>
            <div class="flex flex-row justify-center">
                <button
                    @click.prevent="$root.$emit('logout')"
                    class="relative py-2 px-4 border border-transparent text-sm font-medium rounded-md color-1 bg-color-4"
                >Logout</button>
            </div>
        </template>
    </div>
</template>

<script>
import MessageSnippet from "@/components/common/message-snippet";
import Homepage from "@/components/homepage/homepage";
import {USER_GET_LOGGED_IN, USER_GET_PROFILE_DATA} from "../../store/modules/user";
import DappLoggedInCard from '@/dapp/parts/logged-in-card';

export default {
    name: 'Dashboard',
    components: {
        MessageSnippet,
        Homepage,
        DappLoggedInCard,
    },
    computed: {
        getLoadingText() {
            return `
                You'll be able to check power bank stations on map, see the price and how many power banks are available for each station.<br/>
                Scan is available only in mobile app.
            `;
        },
        isUserLoggedInWithEmail() {
            return this.$store.getters[ 'user/' + USER_GET_LOGGED_IN ];
        },
        userData() {
            return this.$store.getters[ 'user/' + USER_GET_PROFILE_DATA ];
        },
        getGreetingText() {
            if( this.userData.name ) {
                return `Howdy, ${this.userData.name}!`;
            }

            return 'Howdy!';
        }
    }
};
</script>
