<template>
    <div class="cabinets">
        Cabinets

        <table class="table-auto">
            <thead>
                <tr>
                    <th class="w-1/9 px-4 py-2">ID</th>
                    <th class="w-2/9 px-4 py-2">Cabinet ID</th>
                    <th class="w-2/9 px-4 py-2">Address</th>
                    <th class="w-1/9 px-4 py-2">Status</th>
                    <th class="w-5/9 px-4 py-2">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(cabinet, index) of cabinets">
                    <td class="w-1/8 px-4 py-2">{{ cabinet.db_id }}</td>
                    <td class="w-1/4 px-4 py-2">{{ cabinet.cabinet_id }}</td>
                    <td class="w-1/4 px-4 py-2">{{ cabinet.address }}</td>
                    <td class="w-1/8 px-4 py-2">{{ cabinet.status }}</td>
                    <td class="w-1/2 px-4 py-2">
                        <div v-if="cabinet.status === 1">
                            Force Pop:
                            <template v-for="i in 8">
                                <div class="inline-flex">
                                    <button @click.prevent="forcePop(cabinet, i)"
                                            class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4">{{i
                                        }}</button>
                                </div>
                            </template>
                            <hr/>
                            <div class="py-2 px-4">
                                <button @click.prevent="queryInventory(cabinet)">Query inventory</button>
                            </div>
                            <hr/>
                            Borrow:
                            <template v-for="i in 8">
                                <div class="inline-flex">
                                    <button @click.prevent="borrowPowerbank(cabinet, i)"
                                            class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4">{{i
                                        }}</button>
                                </div>
                            </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { GetCabinetsForAdmin } from "@/graphql/admin-cabinets";
import {
    MutationCabinetForcePop,
    MutationCabinetQueryInventory,
    MutationCabinetBorrowPowerbank
} from "@/graphql/cabinets";

export default {
    name: 'Cabinets',
    data() {
        return {
            cabinets: []
        }
    },
    mounted() {
        this.getCabinets();
    },
    methods: {
        getCabinets() {
            this.$apollo.query({
                query: GetCabinetsForAdmin
            }).then((data) => {
                if ( data.data.cabinets.edges.length ) {
                    for ( let i = 0; i < data.data.cabinets.edges.length; i++ ) {
                        this.cabinets.push(data.data.cabinets.edges[i].node);
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        forcePop(cabinet, slot) {
            let input = {
                clientMutationId: 'mutationCabinetForcePopId',
                cabinet_id: cabinet.cabinet_id,
                slot: slot,
            }
            this.$apollo.mutate({
                mutation: MutationCabinetForcePop,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log(data)
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
            })
        },
        borrowPowerbank(cabinet, slot) {
            let input = {
                clientMutationId: 'mutationCabinetBorrowPowerbankId',
                cabinet_id: cabinet.cabinet_id,
                slot: slot,
            }
            this.$apollo.mutate({
                mutation: MutationCabinetBorrowPowerbank,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log('Borrow POWERBANK [APP] -> ', data)
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
            })
        },
        queryInventory(cabinet) {
            let input = {
                clientMutationId: 'mutationCabinetQueryInventoryId',
                cabinet_id: cabinet.cabinet_id,
            }
            this.$apollo.mutate({
                mutation: MutationCabinetQueryInventory,
                variables: {
                    input: input,
                },
            }).then((data) => {
                console.log(data.data.cabinetQueryInventory.message)
                if ( data.data.cabinetQueryInventory.message ) {
                    console.log(JSON.parse(data.data.cabinetQueryInventory.message))
                }
            }).catch((error) => {
                // Error
                console.log('error')
                console.log(error)
            })
        }
    }
};
</script>
