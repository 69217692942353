<template>
    <div
        class="dapp-login__tab"
        v-if="openContent">
        <div
            class="dapp-login__tab-error"
            v-if="error"
            :error="error">
            {{error}}
        </div>
        <div v-else-if="accounts.length === 0" class="dapp-login__tab-info">
            <p class="dapp-login__tab-info-txt">Please connect and unlock your Ledger Wallet.</p>
            <p class="dapp-login__tab-info-txt">Don't forget to open Elrond APP</p>
        </div>
        <div class="dapp-login__tab__ledger">
            <ul class="login__tab__ledger-list flex flex-col">
                <li
                    class="login__tab__ledger-item"
                    v-for="(account, index) in accounts"
                    :key="index">
                    <a
                        class="login__tab__ledger-link group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click="login(startIndex + index)"
                        href="#">{{ account }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// TODO: see https://github.com/wattwo/app/issues/1

export default {
    name: 'ErdjsLoginLedger',
    data () {
        return {
            startIndex: 0,
            accounts: [],
            error: null,
            openContent: false
        }
    },
    props: {
        addressPageSize: {
            type: Number,
            default: 5
        },
        selectedMode: {
            type: String,
            default: ''
        },
        token: {
            require: false,
            type: String
        }
    },
    watch: {
        selectedMode (selectedMode) {
            if ( selectedMode === 'Ledger' ) {
                this.fetchAccounts();
            } else {
                this.openContent = false;
            }
        }
    },
    methods: {
        async fetchAccounts() {
            this.openContent = true;
            this.error = null;
            this.accounts.splice(0);
            this.accounts = await this.$erd.ledger.accounts(this.startIndex, this.startIndex + this.addressPageSize).catch((error) => {
                this.error = "Error while trying to read your Ledger wallet. " +
                    "Please make sure you have unlock it and that your Elrond app is opened";
                return [];
            });
        },
        next() {
            this.startIndex = this.startIndex + this.addressPageSize;
            this.fetchAccounts();
        },
        previous() {
            if (this.startIndex == 0) {
                return;
            }
            this.startIndex=this.startIndex - this.addressPageSize;
            if(this.startIndex <= 0) {
                this.startIndex = 0;
            }
            this.fetchAccounts();
        },
        login(index) {
            const token = this.token ? { token: this.token } : {}
            this.$erd.ledger.login({ addressIndex: index, ...token })
        }
    }
}
</script>
